<template>
  <div class="home">
    <Carousel  />
    <div class="px-5 padding">
      <div class="row">
        <div class="col">
          <ListCategorias />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <Articles />
        </div>
      </div>
      <div class="row">
        <div class="col pb-5 pb-md-0 pb-lg-0">
          <MostBuyArticles />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import HelloWorld from "@/components/HelloWorld.vue";
import { inject, watch, onMounted } from "vue";
import Carousel from "@/components/Carousel.vue";
import Articles from "@/components/Articles.vue";
import ListCategorias from "@/components/ListCategorias.vue";
import MostBuyArticles from "@/components/MostBuyArticles.vue";
// import Swal from 'sweetalert2'

export default {
  name: "Home",
  components: {
    // HelloWorld,
    Carousel,
    Articles,
    ListCategorias,
    MostBuyArticles
  },
  setup() {
    // Swal.fire('Any fool can use a computer');
    const issuserParam = inject("issuserParam");
    const cargarProductos = inject("cargarProductos");
    const paramsProductos = inject("paramsProductos");
    const pagActive = inject("pagActive");

    const instanceProductos = () =>{
      pagActive.value = 1;
      paramsProductos.value.start = pagActive.value;
      paramsProductos.value.categoria = -1;
      paramsProductos.value.grupo = -1;
      paramsProductos.value.search = "";
      paramsProductos.value.orderby = 1;
      delete paramsProductos.value.productos;
      cargarProductos(paramsProductos.value);
    }

    if (issuserParam.value.idLP) {
      instanceProductos();
    }

    watch(
      () => issuserParam.value,
      (newParams) => {
        if (!newParams) return;
        instanceProductos();
      }
    );
    onMounted(()=>{
      window.scrollTo(0, 0);
    });
  },
  methods: {
    changeDarkMode() {
      document.body.classList.toggle("dark-mode");
    },
  },
};
</script>
