<template>
  <div class="container padding pt-md-2 pt-lg-2 px-1 px-lg-5 px-md-5 ancho">

    <div class="row card-deck mt-5 pt-5 mx-lg-5 mx-md-5 mx-0 px-lg-5 px-md-5 px-sm-1 justify-content-center align-items-center content-align-center">

      <h2 id="trans-authid" class="card-title text-center mb-1">
        Compra #{{ datos.Mensaje.issuerReponse.issuerTransactionId }} efectuada con éxito<i class="fas fa-check"></i>
      </h2>


      <div class="card mt-2 p-0 mx-lg-4 mx-md-4 ">
        <div class="card-body">
          <h5 class="card-title mb-1">
            Datos del usuario
          </h5>

          <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
              <div>
                <label class="text-card">Nombre: </label>
                <label class="text-card-data" id="trans-clientname">{{ objFactura.factura.nombre }}</label>
              </div>
              <div>
                <label class="text-card">Tipo Documento: </label>
                <label class="text-card-data" id="trans-clientdocumenttype">{{
                  tipoDocumentos(objFactura.factura.tipoDocumento)
                }}</label>
              </div>
              <div>
                <label class="text-card">Documento: </label>
                <label class="text-card-data" id="trans-clientdocument">{{ objFactura.factura.documento }}</label>
              </div>
              <div>
                <label class="text-card">E-Mail: </label>
                <label class="text-card-data" id="trans-clientmail">{{ envioPedido.emailPedido }}</label>
              </div>

            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
              <label class="text-card">Dirección: </label>
              <label class="text-card-data" id="trans-clientadress">{{ objFactura.factura.domicilio }}</label>

              <div>
                <label class="text-card">Ciudad: </label>
                <label class="text-card-data" id="trans-clientcity">{{ envioPedido.ciudadPedido }}</label>
              </div>
              <div>
                <label class="text-card">Departamento: </label>
                <label class="text-card-data" id="trans-clientstate">{{ objFactura.factura.departamento }}</label>
              </div>
              <div>
                <label class="text-card">País: </label>
                <label class="text-card-data" id="trans-clientcountry">{{ objFactura.factura.pais }}</label>
              </div>

            </div>

          </div>

        </div>

        <div class="card-body">
          <h5 class="card-title mb-1">
            Datos de la Transacción
          </h5>
         <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
              <div>
              <label class="text-card">Factura: {{
                " " + tipoComprobantes(datos.MensajeFE.retorno.tipoComprobante) + " " }} {{ datos.MensajeFE.retorno.serie + " "}} {{ datos.MensajeFE.retorno.numero }}</label>
            </div> 
              <div>
                <label class="text-card">Tarjeta: </label>
                <label class="text-card-data" id="trans-cardnumber">{{ datos.Mensaje.issuerReponse.issuerCard }}</label>
              </div>
              <div>
                <label class="text-card">Vencimiento: </label>
                <label class="text-card-data" id="trans-cardexpdate">{{
                  datos.Mensaje.issuerReponse.issuerCardVto
                }}</label>
              </div>
              <div>
                <label class="text-card">Cod. Autorización: </label>
                <label class="text-card-data" id="trans-authnumber">{{
                  datos.Mensaje.issuerReponse.issuerAuthorizationCode
                }}</label>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
            
              <div>
                <label class="text-card">Transacción Id: </label>
                <label class="text-card-data" id="trans-authid2">{{
                  datos.Mensaje.issuerReponse.issuerTransactionId
                }}</label>
              </div>
              <div>
                <label class="text-card">Cuotas: </label>
                <label class="text-card-data" id="trans-cuotes">{{ objFactura.cuotas }}</label>
              </div>
          
                <div>
                  <label class="text-card">Monto Pagado: </label>
                  <label class="text-card-data" id="trans-amount2">{{
                    objFactura.factura.tipoMoneda + " " +
                    objFactura.formatPrice
                  }}</label>
                </div>
            </div>
          </div>
        </div>
        <div class="card-body border-top py-0">
          <ul class="list-group list-group-flush pt-0 mt-0">
            <li class="list-group-item">
              <div class="row">
                <div class="col-6 fw-bold">Productos</div>
                <div class="col-3 fw-bold text-end px-2">Cantidad</div>
                <div class="col-3 text-align-end fw-bold text-end">SubTotal</div>
              </div>
            </li>
            <li class="list-group-item py-1" v-for="producto in productsComplete.value" :key="producto.codigo">
              <div class="row">
                <div class="col-6">{{ producto.nombreProducto }}</div>
                <div class="col-3 px-2 text-end">{{ producto.cantidad }}</div>
                <div class="col-3 text-align-end fw-bold text-end">{{ formatMoney(objFactura.factura.tipoMoneda) + " "
                  + formatPrice(producto.subTotal, 2) }}</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="card-footer text-center text-md-left text-lg-left pb-0 mb-0">
          <div class="row px-3" id="trans-amount">
            <div class="col-7 text-start">
              <h5>Total:</h5>
            </div>
            <div class="col-5 text-end">
              <h5>{{ formatMoney(objFactura.factura.tipoMoneda) + " " + objFactura.formatPrice }} </h5>
            </div>
          </div>
        </div>
        
      </div>
     
      <div class="row text-center content-align-center justify-content-center align-items-center pb-5 pb-md-0 mt-3 mx-3 pb-lg-0">
          <div class="col-auto pb-5 pb-md-0 pb-lg-0">
            <button class="btn btn-client mb-2 mt-2" @click="volverPayExpress">
              <fa icon="cart-arrow-down" /> Continuar Comprando
            </button>
          </div>
      </div>
   

    </div>
  </div>
</template>
<script>

import { inject } from "vue";
import router from "@/router";
import { borrarCarrito, formatMoney, tipoComprobantes, formatPrice, tipoDocumentos } from "@/composable/functions";
export default {


  setup() {
    const datos = inject("datos");
    const objFactura = inject("objfactura");
    const envioPedido = inject("objEnvioPedido");
    const cart = inject("cart");
    const productsComplete = inject("productsComplete")


    console.log("productsComplete" + JSON.stringify(productsComplete.value))



    const volverPayExpress = () => {
      borrarCarrito();
      cart.value = [];

      router.push({ name: 'Home' });
    }
    return { router, datos, objFactura, envioPedido, volverPayExpress, productsComplete, formatMoney, tipoComprobantes, formatPrice, tipoDocumentos };
  }
}

</script>
<style>
   @media(min-width: 992px){
     .ancho{
        width: 60%!important;
     }

   }

</style>

