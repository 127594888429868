<template>
  <!-- <Navbar /> -->
  <div class="offcanvas offcanvas-start bg-light" tabindex="-1" ref="offcanvasExample" id="offcanvasExample"
    aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-header bg-nav-client">
      <h5 class="offcanvas-title" id="offcanvasExampleLabel">
        <img :src="issuserParam.rutaLogo + issuserParam.logo" height="100" class="img-fluid" />
      </h5>
      <button type="button" class="btn text-black" data-bs-dismiss="offcanvas" aria-label="Close">
        <fa icon="arrow-left" class="fa-2x" />
      </button>
    </div>
    <div class="offcanvas-body">
      <a class="
          d-flex
          align-items-center
          pb-3
          mb-3
          link-dark
          text-decoration-none
          border-bottom
        ">
        <span class="fs-5 fw-semibold">{{ issuserParam.nombreComercial }}</span>
      </a>
      <div class="row">
        <label for="button-addon2" class="form-label">Buscar: </label>
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Buscar..." aria-label="Buscar"
            aria-describedby="button-addon2" v-model="paramsProductos.search" @keyup.enter="changeCategoria" />
          <button class="btn btn-outline-secondary" type="button" v-on:click="borrarFiltros" title="Borrar Filtros">
            <fa icon="backspace" />
          </button>
          <button class="btn btn-outline-secondary" type="button" v-on:click="changeCategoria" title="Buscar">
            <fa icon="search" />
          </button>
        </div>
      </div>
      <div class="row mb-3" v-if="!tieneGruposCategorias">
        <label for="grupo_categoria_filtro" class="form-label">Categoría:
        </label>
        <div class="input-group">
          <select class="form-select" id="grupo_categoria_filtro" v-model="paramsProductos.categoria"
            @change="changeCategoria">
            <option value="-1">TODOS</option>
            <option :value="categoria.codigo" v-for="categoria in categorias" :key="categoria.codigo">
              {{ categoria.nombre }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mb-3" v-if="tieneGruposCategorias">
        <label for="categoria_filtro" class="form-label">Categoría</label>
        <div class="input-group">
          <select class="form-select" id="categoria_filtro" v-model="paramsProductos.grupo" @change="changeCategoria">
            <option value="-1">Todas</option>
            <option v-for="categoria in categorias" :key="categoria.codigoGrupo" :value="categoria.codigoGrupo">
              {{ categoria.nombreGrupo }}
            </option>
          </select>
        </div>
        <label for="categoria_filtro" class="form-label">Sub Categorías</label>
        <div class="input-group">
          <select class="form-select" id="subCategoria_filtro" v-model="paramsProductos.categoria"
            @change="changeSubCategoriaCategoria">
            <option value="-1">Todas</option>
            <option v-for="subCategoria in subCategorias" :key="subCategoria.codigo" :value="subCategoria.codigo">
              {{ subCategoria.nombre }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mb-3">
        <label for="ordenar_filtro" class="form-label">Ordenar:</label>
        <div class="input-group">
          <select class="form-select" id="ordenar_filtro" v-model="paramsProductos.orderby" @change="changeCategoria">
            <option value="1">Alfabéticamente</option>
            <option value="2">Menor Precio</option>
            <option value="3">Mayor Precio</option>
          </select>
        </div>
      </div>
      <div class="row border-bottom"></div>
      <ul class="list-unstyled ps-0">
        <li class="mb-1">
          <button class="
              btn btn-toggle
              align-items-center
              rounded
              collapsed
              text-black
            " data-bs-toggle="collapse" data-bs-target="#home-collapse" aria-expanded="true">
            <fa icon="home" class="" /> Inicio
          </button>
          <div class="collapse show pl-5" id="home-collapse">
            <ul class="btn-toggle-nav list-unstyled pb-1 small">
              <li>
                <a href="javascript:void(0);" class="dropdown-item ps-5" :class="inicioHash.verProductos.class"
                  @click="borrarFiltros">
                  <fa icon="arrow-right" class="" /> Ver Productos
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" class="dropdown-item ps-5" :class="inicioHash.verFavoritos.class"
                  @click="mostrarFavoritos">
                  <fa icon="arrow-right" class="" /> Ver Favoritos
                </a>
              </li>
              <li class="d-none">
                <a href="javascript:void(0);" class="dropdown-item ps-5">
                  <fa icon="arrow-right" class="" /> Ver Compras
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li class="mb-1 d-none">
          <button class="
              btn btn-toggle
              align-items-center
              rounded
              collapsed
              text-black
            " data-bs-toggle="collapse" data-bs-target="#dashboard-collapse" aria-expanded="false">
            <fa icon="cogs" class="" /> Configuración
          </button>
          <div class="collapse" id="dashboard-collapse">
            <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li>
                <a href="javascript:void(0);" class="dropdown-item ps-5">
                  <fa icon="arrow-right" class="" /> Visuales
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" class="dropdown-item ps-5">
                  <fa icon="arrow-right" class="" /> Administración
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li class="border-top my-3"></li>
        <li class="mb-1 d-none">
          <button class="
              btn btn-toggle
              align-items-center
              rounded
              collapsed
              text-black
            " data-bs-toggle="collapse" data-bs-target="#account-collapse" aria-expanded="false">
            <fa icon="user" class="" /> Account
          </button>
          <div class="collapse" id="account-collapse">
            <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li>
                <a href="javascript:void(0);" class="dropdown-item ps-5">
                  <fa icon="arrow-right" class="" /> Administración
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="row mx-1 mb-2">
        <span style="font-size:0.8em" class="ordenar_filtro">App.V.{{ app_v }} - Api.V.{{ api_v }}</span>
    </div>
  </div>
</template>

<script>
// import Navbar from "@/components/Navbar.vue";
import { inject, computed } from "vue";
import { Offcanvas } from "bootstrap";
import { useRoute } from "vue-router";
import { goTo } from "@/composable/functions";
import router from "@/router";
export default {
  name: "slidebar",
  components: {
    // Navbar
  },
  data() {
    const app_v = inject("app_version");
    return {
      touchstartX: 0,
      touchstartY: 0,
      touchendX: 0,
      touchendY: 0,
      canvas: "",
      adjfactor: 100,
      iconDarkLigth: "sun",
      app_v
    };
  },
  setup() {
    const route = useRoute();
    const categorias = inject("categorias");
    const tieneGruposCategorias = inject("tieneGruposCategorias");
    const paramsProductos = inject("paramsProductos");
    const cargarProductos = inject("cargarProductos");
    const pagActive = inject("pagActive");
    const issuserParam = inject("issuserParam");
    const inicioHash = inject("inicioHash");
    const api_v = inject("api_version");
    const subCategorias = computed(() => {
      let array = [];
      if (paramsProductos.value.grupo != -1) {
        const categoria = categorias.value.find((categoria) => {
          return categoria.codigoGrupo === paramsProductos.value.grupo;
        });
        array = categoria.items;
      } else {
        categorias.value.forEach((categoria) => {
          array.push(...categoria.items);
        });
      }
      return array;
    });

    const changeCategoria = () => {
      pagActive.value = 1;
      paramsProductos.value.start = pagActive.value;
      cargarProductos(paramsProductos.value, sendFiltros);
    };

    const changeSubCategoriaCategoria = () => {
      pagActive.value = 1;
      paramsProductos.value.start = pagActive.value;
      cargarProductos(paramsProductos.value, sendFiltros);
    };

    const changeOrden = () => {
      pagActive.value = 1;
      paramsProductos.value.start = pagActive.value;
      cargarProductos(paramsProductos.value, sendFiltros);
    };

    const sendFiltros = () => {
      if (tieneGruposCategorias && paramsProductos.value.grupo != -1) {
        router.push({
          name: "Catalog",
          params: { id: paramsProductos.value.grupo },
        });
      } else if (
        !tieneGruposCategorias &&
        paramsProductos.value.categoria != -1
      ) {
        router.push({
          name: "Catalog",
          params: { id: paramsProductos.value.categoria },
        });
      } else {
        console.log("aca")
        router.push({ name: "Catalog" });
      }
      goTo("articlesDisplay", 100);
    };

    const borrarFiltros = () => {
      pagActive.value = 1;
      paramsProductos.value.start = pagActive.value;
      paramsProductos.value.categoria = -1;
      paramsProductos.value.grupo = -1;
      paramsProductos.value.search = "";
      paramsProductos.value.orderby = 1;
      delete paramsProductos.value.productos;
      cargarProductos(paramsProductos.value, () => {
        inicioHash.verProductos.class = "active";
        inicioHash.verFavoritos.class = "";
        sendFiltros();
      });
    };

    const mostrarFavoritos = () => {
      pagActive.value = 1;
      paramsProductos.value.start = pagActive.value;
      paramsProductos.value.categoria = -1;
      paramsProductos.value.grupo = -1;
      paramsProductos.value.search = "";
      paramsProductos.value.orderby = 1;
      paramsProductos.value.productos = localStorage.getItem("fav")
        ? localStorage.getItem("fav")
        : "";
      cargarProductos(paramsProductos.value, () => {
        inicioHash.verProductos.class = "";
        inicioHash.verFavoritos.class = "active";
        if (route.path !== "/") {
          router.push({ name: "Home" });
        }
        goTo("articlesDisplay", 100);
      });
    };

    return {
      categorias,
      tieneGruposCategorias,
      paramsProductos,
      changeCategoria,
      changeOrden,
      issuserParam,
      subCategorias,
      changeSubCategoriaCategoria,
      borrarFiltros,
      inicioHash,
      mostrarFavoritos,
      api_v
    };
  },
  mounted() {
    this.canvas = new Offcanvas(document.getElementById("offcanvasExample"));
    document.addEventListener("touchstart", this.touchstart);

    document.addEventListener("touchend", this.touchend);
    this.instanceVisualMode();
  },
  unmounted() {
    document.removeEventListener("touchstart", this.touchstart);
    document.removeEventListener("touchend", this.touchend);
  },
  methods: {
    changeDarkMode() {
      document.body.classList.toggle("dark-mode");
      this.instanceVisualMode();
      if (document.body.classList.contains("dark-mode")) {
        localStorage.setItem("visualMode", "dark");
      } else {
        localStorage.setItem("visualMode", "ligth");
      }
    },
    instanceVisualMode() {
      if (document.body.classList.contains("dark-mode")) {
        this.iconDarkLigth = "sun";
      } else {
        this.iconDarkLigth = "moon";
      }
    },
    touchstart(event) {
      this.touchstartX = event.changedTouches[0].screenX;
      this.touchstartY = event.changedTouches[0].screenY;
    },
    touchend(event) {
      this.touchendX = event.changedTouches[0].screenX;
      this.touchendY = event.changedTouches[0].screenY;
      this.handleGesture();
    },
    handleGesture() {
      if (
        this.touchendX < this.touchstartX &&
        Math.abs(this.touchendX - this.touchstartX) > this.adjfactor &&
        Math.abs(this.touchendY - this.touchstartY) <
        Math.abs(this.touchendX - this.touchstartX)
      ) {
        // console.log('De derefha a izquierda');
        if (
          document.getElementById("offcanvasExample").classList.contains("show")
        ) {
          this.canvas.hide();
        }
      }

      if (
        this.touchendX > this.touchstartX &&
        Math.abs(this.touchendX - this.touchstartX) > this.adjfactor &&
        Math.abs(this.touchendY - this.touchstartY) <
        Math.abs(this.touchendX - this.touchstartX)
      ) {
        // console.log('De izquierda a derecha');
        if (
          !document
            .getElementById("offcanvasExample")
            .classList.contains("show")
        ) {
          this.canvas.show();
        }
        // document.getElementById('offcanvasId').click();
      }

      if (this.touchendY < this.touchstartY) {
        // console.log('Hacia arriba');
      }

      if (this.touchendY > this.touchstartY) {
        // console.log('Hacia abajo');
      }

      if (this.touchendY === this.touchstartY) {
        // console.log('Solo he tocado');
      }
    },
  },
};
</script>

<style>

</style>