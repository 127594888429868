<template>
  <div class="row mt-2 border-top border-2" id="cont_categorias">
    <div class="col">
      <div class="row justify-content-center align-items-center">
        <div class="col-auto" v-for="categoria in categorias" :key="categoria.codigoGrupo"
          v-bind:id="'categoria_' + categoria.codigoGrupo">
          <div class="card bg-transparent text-white border-0 mt-1" v-if="categoria.imagenGrupo">
            <a href="javascript:void(0);" @click="sendCatalogGrupo(categoria.codigoGrupo)">
              <img :src="categoria.path + categoria.imagenGrupo" class="card-img" :alt="categoria.nombreGrupo"
                style="max-height: 300px" />
              <div class="card-img-overlay card-hover">
                <div class="bg-semi-black-transparent">
                  <h5 class="card-title text-center nav-link text-white">
                    {{ categoria.nombreGrupo }}
                  </h5>
                  <!-- <p class="card-text">
              This is a wider card with supporting text below as a natural lead-in
              to additional content. This content is a little bit longer.
            </p>
            <p class="card-text">Last updated 3 mins ago</p> -->
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import router from "@/router";
export default {
  data() {
    return {
    }
  },
  setup() {
    const categorias = inject("categorias");
    const tieneGruposCategorias = inject("tieneGruposCategorias");
    const inicioHash = inject("inicioHash");

    setTimeout(() => {
       if(document.getElementById('categoria_1')!= null){
        const categoria = document.getElementById('categoria_1');
        categoria.classList.add("categoria_1");
       }   
      }, 300);

    const sendCatalogGrupo = (grupo) => {
      router.push({ name: "Catalog", params: { id: grupo } });
      inicioHash.verProductos.class = "active";
      inicioHash.verFavoritos.class = "";
    };

    const sendCatalogCategoria = (categoria) => {
      router.push({ name: "Catalog", params: { id: categoria } });
      inicioHash.verProductos.class = "active";
      inicioHash.verFavoritos.class = "";
    };

    return {
      categorias,
      tieneGruposCategorias,
      sendCatalogGrupo,
      sendCatalogCategoria,
    };
  },
};
</script>

<style type="scss">
@media(max-width: 576px) {
  .categoria_1{
    padding-top: 5%;
  }
  #cont_categorias{
    margin-top: 0%!important;
  }
}

</style>