<template>
  <div class="form-check">
    <input class="form-check-input radio-checkout" type="radio" name="radioMediopago" 
      id="radioMedioPago_MercadoPago" />
    <button type="button" class="btn btn-outline-light border-0">
      <img class="img-fluid" src="img/others/merpag.png" alt="Factura Express" style="max-height: 50px" />
    </button>
  </div>
</template>

<script>
import { inject } from "vue";
import Swal from "sweetalert2";
export default {
  name: "MercadoPago",
  setup() {
    let cliente = inject("cliente");
    const validarCliente = inject("validarCliente");
    const postApiGeneric = inject("postApiGeneric");
    const precioEnvio = inject("precioEnvioCheckout");
    const issuserParam = inject("issuserParam");
    const products = inject("productsCheckout");
    const total = inject("totalCheckout");
    const computedTotal = inject("computedTotalCheckout");

    const facturarMercadoPago = () => {
      if (!validarCliente()) {
        return;
      }
      var carritoDD = [
        { variable: "moneda", variableValor: issuserParam.value.monedalp },
      ];
      var objEnvioPedido = {};
      objEnvioPedido.nombrePedido = cliente.nombre;
      objEnvioPedido.direccionPedido = cliente.direccion;
      objEnvioPedido.ciudadPedido = cliente.ciudad;
      objEnvioPedido.emailPedido = cliente.email;
      objEnvioPedido.telefonoPedido = cliente.telefono;
      objEnvioPedido.comentarioPedido = cliente.comentario;
      objEnvioPedido.documentoReceptor = cliente.documentoReceptor;
      objEnvioPedido.departamentoPedido = cliente.departamento;
      objEnvioPedido.tipoDocumento = cliente.tipoDocumentoReceptor;
      objEnvioPedido.idPrecioEnvio = precioEnvio.value.idPrecio;
      objEnvioPedido.carritoDD = carritoDD;
      objEnvioPedido.total = computedTotal.value;
      objEnvioPedido.totalSinCambios = total.value;

      Swal.fire({
        title: "Enviando a Mercado Pago",
        html: "Esto puede tardar unos segundos",
        didOpen: () => {
          Swal.showLoading();
          postApiGeneric(
            "mercadoPago/facturar",
            { objPedido: objEnvioPedido, listaCarritoCompras: products.value },
            (retorno) => {
              if (retorno.Cod === 200) {
                window.location.replace(retorno.Url);
                return retorno;
              }
            }
          );
        },
      }).then((result) => {
        if (result.dismiss) {
          Swal.fire({
            title: `Confirme su compra`,
          });
        }
      });
    };
    return { facturarMercadoPago };
  },
};
</script>

<style>
</style>