<template>
  <footer class="text-center bg-nav-client footer mt-4 d-block w-100">
   
    <div class="row pb-3 pt-3 w-100">
      <div class="col-md-2 col-lg-2 col-6 order-md-1 order-lg-1 order-2 pl">
        <div class="img-container d-inline-block ">
          <a title="By Gsoft Uruguay" style="cursor: pointer;" target="_blank" href="https://www.gsoft.com.uy">
            <img src="/img/logo/gsoft.png" alt="Gsoft" class="img-logo" />
          </a>
        </div>
      </div>
      <div class="col-md-8 col-lg-8 col-auto order-md-2 order-lg-2 order-1">
        <div style="margin-left: 0 !important;margin-right: 0 !important;"
          class="row pb-md-3 pb-lg-3 pb-4 justify-content-center">

          <div class="col-auto" v-if="issuserParam.direccion">
            <span class="text-client">
              <fa icon="home" /> {{ issuserParam.direccion }}
            </span>
          </div>
          <div class="col-auto" v-if="issuserParam.email">
            <span class="text-client">
              <fa icon="envelope" /> {{ issuserParam.email }}
            </span>
          </div>
          <div class="col-auto" v-if="issuserParam.whatsapp">
            <span class="text-client">
              <fa :icon="['fab', 'whatsapp']" /> {{ issuserParam.whatsapp }}
            </span>
          </div>
          <div class="col-auto" v-if="issuserParam.telefono">
            <span class="text-client">
              <fa icon="phone" /> {{ issuserParam.telefono }}
            </span>
          </div>
          <div style="height:30px!important" class="col-12 col-md-auto col-lg-auto mt-md-0 mt-lg-0 mt-3">
            <a id="seguirFacebook" class="btn border text-client mx-1 btn-redsocial-footer" target="_blank"
              title="Enviar siguenos" :href="[
                `https://www.facebook.com/${issuserParam.facebook}`,
              ]" v-if="issuserParam.facebook">
              <fa :icon="['fab', 'facebook']" />
            </a>
            <a id="seguirInstagram" class="btn text-client border mx-1 btn-redsocial-footer" target="_blank"
              title="Enviar siguenos" :href="[
                `https://www.instagram.com/${issuserParam.instagram}`,
              ]" v-if="issuserParam.instagram">
              <fa :icon="['fab', 'instagram']" />
            </a>
            <a id="seguirtwitter" class="btn border text-client mx-1 btn-redsocial-footer" target="_blank"
              title="Enviar siguenos" :href="[`https://www.twitter.com/${issuserParam.twitter}`]"
              v-if="issuserParam.twitter">
              <fa :icon="['fab', 'twitter']" />
            </a>
          </div>

        </div>
        <div class="row" v-if="sellosPayExpress.length && sellosSiemprePago.length">
          <div class="col text-center">
            <h4>Medios de Pago</h4>
          </div>
          <div class="row justify-content-center align-items-center">
            <div class="col-4 col-sm-4 col-md-2" v-for="payExpress in sellosPayExpress" :key="payExpress.tipo">
              <img :src="payExpress.logo" :alt="payExpress.sello" class="img-fluid" />
            </div>
            <div class="col-4 col-sm-4 col-md-2" v-for="siemprePago in sellosSiemprePago" :key="siemprePago.tipo">
              <img :src="siemprePago.logo" :alt="siemprePago.sello" class="img-fluid" />
            </div>
          </div>
        </div>
        <div v-if="issuserParam.whatsapp && issuserParam.MostrarEnlaceWppSN == '2'">
          <a type="button" :href="['https://wa.me/598' + issuserParam.whatsapp]" style="z-index:10"
            class="Btn-Whatsapp">
            <img class="img-fluid" src="/img/icons/whatsapp-Icon.png" />
          </a>
        </div>
      </div>
      <div class="col-md-2 col-lg-2 col-6 order-3 d-inline-block" style="padding-right: 0%!important;">
        <small class="text-client float-right " style="font-family: 'Montserrat', sans-serif;font-size: 0.700em;">©
          Copyright 2023 / Gsoft</small>
      </div>
    </div>
  </footer>
</template>

<script>
import { inject, watch, ref } from "vue";
export default {
  setup() {
    const issuserParam = inject("issuserParam");
    const getApiGeneric = inject("getApiGeneric");
    const sellosPayExpress = ref([]);
    const sellosSiemprePago = ref([]);
    const api_v = inject("api_version");
    const instancePasarelas = () => {
      getApiGeneric("pasarelas/payExpress", null, (pasarela) => {
        sellosPayExpress.value = pasarela.retorno;
      });
      getApiGeneric("pasarelas/siemprePago", null, (pasarela) => {
        sellosSiemprePago.value = pasarela.retorno;
      });
    };

    if (issuserParam.value.idLP) {
      instancePasarelas();
    }

    watch(
      () => issuserParam.value,
      (newParams) => {
        if (!newParams) return;
        instancePasarelas();
      }
    );
    return { issuserParam, sellosPayExpress, sellosSiemprePago, api_v };
  },
  data() {

    const app_v = inject("app_version");
    return { app_v }
  }

};
</script>

<style>
.Btn-Whatsapp {
  position: fixed;
  bottom: 60px;
  right: 20px;
  height: 50px;
  width: 50px;
}

/*.btn-instagram {
  color: #fff !important;
  background-color: rgb(138, 58, 185) !important;
  border-color: rgb(138, 58, 185) !important;
}*/

html {
  min-height: 100%;
  position: relative;
}

body {
  margin: 0;
  margin-bottom: 80px;
}

footer {

  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
}

.img-logo {
  height: 20px !important;
  width: auto !important;
}

@media(min-width: 576px) {
  .pl {
    padding-left: 2% !important;
  }
}

@media(max-width: 576px) {
  .pl {
    padding-left: 2% !important;
  }
}
</style>