<template>
  <div class="modal fade" id="payExpressPrevia" style="" tabindex="-1" role="dialog"
    aria-labelledby="tituloPayExpressPrevia" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="tituloPayExpressPrevia">Elija Cuotas</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row pb-2">
            <div class="col">
              <select class="form-select" id="selectCuotasPayExpress" aria-label="Pay express Cuotas"
                v-model="cuotaSelected">
                <option v-for="cuota in cuotas" :key="cuota" :value="cuota" :selected="cuota === 0"
                  :disabled="cuota === 0">
                  {{ cuota === 0 ? "Elija Una Cuota" : cuota }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button class="btn btn-client btn-outline-success" type="button" id="btnPagarPayExpress"
                @click="cargarPayExpress">
                Pagar
              </button>
              <button class="btn btn-outline-success d-none" type="button" id="openmodalpayExpress"
                data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#payExpressModal">
                Pagar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade pb-5" id="payExpressModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" id="buttonModalCloseStatic" data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div id="iframePago" style="height: 800px"></div>
          <div class="row mt-3">
            <div id="form-complete" style="display: none">
              <div class="card">
                <h5 id="trans-authid" class="card-header text-dinamic">
                  Error. <i class="fas fa-check"></i>
                </h5>
                <div class="card-body">
                  <div id="payexpressDinamicScript"></div>
                  <div class="card">
                    <h6 class="card-header">
                      Datos del usuario
                    </h6>
                    <div>
                      <label class="text-card">Nombre: </label>
                      <label class="text-card-data" id="trans-clientname">Error.</label>
                    </div>
                    <div>
                      <label class="text-card">País: </label>
                      <label class="text-card-data" id="trans-clientcountry">Error.</label>
                    </div>
                    <div>
                      <label class="text-card">Ciudad: </label>
                      <label class="text-card-data" id="trans-clientcity">Error.</label>
                    </div>
                    <div>
                      <label class="text-card">Departamento: </label>
                      <label class="text-card-data" id="trans-clientstate">Error.</label>
                    </div>
                    <div>
                      <label class="text-card">Dirección: </label>
                      <label class="text-card-data" id="trans-clientadress">Error.</label>
                    </div>
                    <div>
                      <label class="text-card">Tipo Documento: </label>
                      <label class="text-card-data" id="trans-clientdocumenttype">Error.</label>
                    </div>
                    <div>
                      <label class="text-card">E-Mail: </label>
                      <label class="text-card-data" id="trans-clientmail">Error.</label>
                    </div>
                    <div>
                      <label class="text-card">Documento: </label>
                      <label class="text-card-data" id="trans-clientdocument">Error.</label>
                    </div>
                  </div>
                  <br>
                  <div>
                    <div class="card">
                      <h6 class="card-header">
                        Datos del pago
                      </h6>
                      <div>
                        <label class="text-card">Tarjeta: </label>
                        <label class="text-card-data" id="trans-cardnumber">****</label>
                      </div>
                      <div>
                        <label class="text-card">Vencimiento: </label>
                        <label class="text-card-data" id="trans-cardexpdate">/</label>
                      </div>
                      <div>
                        <label class="text-card">Cod. Autorización: </label>
                        <label class="text-card-data" id="trans-authnumber">Error.</label>
                      </div>
                      <div>
                        <label class="text-card">transacción Id: </label>
                        <label class="text-card-data" id="trans-authid2">Error.</label>
                      </div>
                      <div>
                        <label class="text-card">Cuotas: </label>
                        <label class="text-card-data" id="trans-cuotes">Error.</label>
                      </div>
                      <div>
                        <div>
                          <label class="text-card">Monto Pagado: </label>
                          <label class="text-card-data" id="trans-amount2">Error.</label>
                        </div>
                      </div>
                    </div>
                    <br>
                    <div class="card">
                      <h6 class="card-header">
                        Datos de la factura
                      </h6>
                      <div>
                        <label class="text-card">Factura Numero: </label>
                        <label class="text-card-data" id="trans-invoicenumber">Error.</label>
                      </div>
                      <div>
                        <label class="text-card">Factura Serie: </label>
                        <label class="text-card-data" id="trans-invoiceserial">Error.</label>
                      </div>
                      <div>
                        <label class="text-card">Factura Tipo: </label>
                        <label class="text-card-data" id="trans-invoicetype">Error.</label>
                      </div>
                    </div>
                    <div>
                      <br>
                      <h6 id="trans-amount">Error. </h6>
                    </div>
                  </div>
                  <hr />
                  <a href="javascript:void(0);" id="volverPayExpress" class="btn btn-client"
                    @click="volverPayExpress">Volver</a>
                </div>
              </div>
            </div>
            <div id="form-error" style="display: none">
              <div class="card">
                <h5 class="card-header text-danger">
                  <i class="fas fa-times-circle"></i>
                  Error
                </h5>
                <div class="card-body">
                  <h5 class="card-title">
                    Ha habido un problema,Vuelva a intentar. si el error persiste comuníquese con el proveedor
                    ofreciendo el siguiente Id de transacción:
                  </h5>
                  <hr />
                  <div>
                    <label>Id. Transacción: </label>
                    <label id="trans-error-code">Error.</label>
                  </div>
                  <a href="javascript:void(0);" @click="volverPayExpress" class="btn btn-danger">Volver</a>
                </div>
              </div>
            </div>
            <div id="form-fault" style="display: none">
              <div class="card">
                <h5 class="card-header text-danger">
                  <i class="fas fa-info-circle"></i>
                  No se pudo completar la compra.
                </h5>
                <div class="card-body">
                  <h5 id="trans-info-msg">Error.</h5>
                  <h6 id="trans-info-details">Error_details.</h6>
                  <br />

                  <div>
                    <label>Id. Transacción: </label>
                    <label id="trans-info-authid">Error.</label>
                  </div>
                  <div>
                    <label>Codigo de Respuesta: </label>
                    <label id="trans-info-respcode">Error.</label>
                  </div>

                  <br />

                  <div>
                    <label>Tarjeta: </label>
                    <label id="trans-info-cardnumber">****</label>
                  </div>
                  <div>
                    <label>Vencimiento: </label>
                    <label id="trans-info-cardexpdate">/</label>
                  </div>
                  <hr />
                  <a href="#" class="btn btn-danger"  @click="volverPayExpress">Volver</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" id="closeModalOpen" style="display: none">
          <a role="button" class="btn btn-secondary" data-bs-dismiss="modal" href="javascript:void(0);"
            @click="volverPayExpress">
            Volver
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, watch, computed, ref } from "vue";
import { borrarCarrito } from "@/composable/functions";
import { formatPricePayexpress, randomTransactionId } from "@/composable/functions";
import { Modal } from "bootstrap";
import Swal from "sweetalert2";
import router from "@/router";
export default {
  setup(props, { expose }) {
    /* eslint-disable */
    let modalPrevia;
    const postApiGeneric = inject("postApiGeneric");
    const issuserParam = inject("issuserParam");
    const decimal = inject("decimal");
    const payExpressSelected = inject("payExpressSelected");
    const computedTotalCheckout = inject("computedTotalCheckout");
    const totalCheckout = inject("totalCheckout");
    const products = inject("productsCheckout");
    const styleClass = inject("styleClass");
    const precioEnvio = inject("precioEnvioCheckout");
    const cliente = inject("cliente");
    const pedidoFE = inject("pedidoFE");
    const datos = inject("datos");
    const objFactura = inject("objfactura");
    const envioPedido = inject("objEnvioPedido");
    const cart = inject("cart");
    const colCarrito = ref([]);
    const cuotaSelected = ref(0);

    const cuotas = computed(() => {
      let arrayCuotas = [0];
      if (!payExpressSelected.value.cuotas) {
        return arrayCuotas;
      }
      for (let i = 1; i <= payExpressSelected.value.cuotas; i++) {
        arrayCuotas.push(i);
      }
      return arrayCuotas;
    });



    const instancePayExpress = () => {
      const scripts = [
        `https://sistemas.payexpress.com.uy/v1/gs-checkout-frontend/api.js`,
        `https://sistemas.payexpress.com.uy/v1/lib/jsencrypt.js`,
      ];

      scripts.forEach((script) => {
        let tag = document.createElement("script");
        tag.setAttribute("src", script);
        document.head.appendChild(tag);
      });
    };

    if (issuserParam.value.url_Payexpress) {
      if (window.GSCheckOut == undefined) {
        instancePayExpress();
      }
    }

    watch(
      () => issuserParam.value,
      (newParams) => {
        if (!newParams) return;
        instancePayExpress();
      }
    );

    const cargarCuotas = (fn) => {
      modalPrevia = new Modal(document.getElementById("payExpressPrevia"));
      if (fn) {
        fn(modalPrevia);
      }
    };

    const cargarPayExpress = () => {
      if (!cuotaSelected.value) {
        Swal.fire("Debe seleccionar una cuota.", "", "warning");
        return;
      }
      modalPrevia.hide();
      const modal = new Modal(document.getElementById("payExpressModal"));
      modal.show();

      window.GSCheckOut.load(
        "iframePago",
        `${issuserParam.value.url_Payexpress}?sello=${payExpressSelected.value.tipo
        }&apikey=${issuserParam.value.keyPayExpress
        }&amount=${computedTotalCheckout.value
          .toFixed(2)
          .replace(".", "")}&currency=${issuserParam.value.monedalp}&cuotas=${cuotaSelected.value
        }&name=${cliente.nombre}&email=${cliente.email}&doc=${cliente.documentoReceptor
        }`
      );

      window.GSCheckOut.Bind("tokenCreated", function (token) {
        let objPayExpress = {};
        objPayExpress.token = token;
        objPayExpress.transactionId = randomTransactionId();
        objPayExpress.amount = computedTotalCheckout.value.toFixed(2).replace(".", "");
        objPayExpress.currencyCode = issuserParam.value.monedalp;
        objPayExpress.cuotas = cuotaSelected.value;
        let factura = {};
        factura.pais = "UY";
        factura.ciudad = cliente.ciudad;
        factura.departamento = cliente.departamento;
        factura.domicilio = cliente.direccion;
        factura.nombre = cliente.nombre;
        factura.tipoDocumento = cliente.tipoDocumentoReceptor;
        factura.documento = cliente.documentoReceptor;
        factura.montoGravado = computedTotalCheckout.value.toFixed(2).replace(".", "");
        factura.montoPagar = computedTotalCheckout.value.toFixed(2).replace(".", "");
        factura.montoTotal = computedTotalCheckout.value.toFixed(2).replace(".", "");
        factura.numero = "1";
        factura.serie = "A";
        factura.tipoComprobante = "101";
        factura.tipoMoneda = issuserParam.value.monedalp;
        factura.tipoOperacion = "C";
        objPayExpress.factura = factura;

        let idPrecioEnvio = precioEnvio.value.idPrecio;
        let carritoDD = [
          { variable: "moneda", variableValor: issuserParam.value.monedalp },
        ];
        let objEnvioPedido = {};
        objEnvioPedido.nombrePedido = cliente.nombre;
        objEnvioPedido.direccionPedido = cliente.direccion;
        objEnvioPedido.ciudadPedido = cliente.ciudad;
        objEnvioPedido.emailPedido = cliente.email;
        objEnvioPedido.telefonoPedido = cliente.telefono;
        objEnvioPedido.comentarioPedido = cliente.comentario;
        objEnvioPedido.documentoReceptor = cliente.documentoReceptor;
        objEnvioPedido.departamentoPedido = cliente.departamento;
        objEnvioPedido.tipoDocumento = cliente.tipoDocumentoReceptor;
        objEnvioPedido.idPrecioEnvio = idPrecioEnvio;
        objEnvioPedido.nroInterno = objPayExpress.transactionId;
        objEnvioPedido.carritoDD = carritoDD;
        objEnvioPedido.whatsappEmisor = issuserParam.value.whatsapp;
        objEnvioPedido.telefonoEmisor = issuserParam.value.telefono;
        objEnvioPedido.mailEmisor = issuserParam.value.email;
        objEnvioPedido.nombreComercialEmisor = issuserParam.value.nombreComercial;
        objEnvioPedido.logoEmisor = issuserParam.value.rutaLogo + issuserParam.value.logo;
        objEnvioPedido.total = computedTotalCheckout.value;
        objEnvioPedido.totalSinCambios = totalCheckout.value;

        postApiGeneric(
          "pasarelas/payExpress",
          {
            factura: objPayExpress,
            objPedido: objEnvioPedido,
            listaCarritoCompras: products.value
          },
          (data) => {
            if (data.Cod === 200) 
            {
              document.getElementById("buttonModalCloseStatic").style.display = "none";
              if (
                data.Mensaje.issuerReponse.issuerResponseCode == "00" ||
                data.Mensaje.issuerReponse.issuerResponseCode == "0"
              ) {

                pedidoFE.value = data.Mensaje;
                datos.value = data;
                objPayExpress.formatPrice = formatPricePayexpress(factura.montoTotal, decimal);
                objFactura.value = objPayExpress;
                envioPedido.value = objEnvioPedido;
                modal.hide();
                router.push({ name: "CompletePayExpress" });

              } else {
                document.getElementById("trans-info-respcode").innerText =
                  data.Mensaje.issuerReponse.issuerResponseCode;
                document.getElementById("trans-info-details").innerText =
                  data.Mensaje.issuerReponse.issuerAditionalData;
                document.getElementById("trans-info-authid").innerText =
                  data.Mensaje.issuerReponse.issuerTransactionId;
                document.getElementById("trans-info-cardnumber").innerText =
                  data.Mensaje.issuerReponse.issuerCard;
                document.getElementById("trans-info-cardexpdate").innerText =
                  data.Mensaje.issuerReponse.issuerCardVto;
                document.getElementById("trans-info-msg").innerText =
                  data.Mensaje.issuerReponse.issuerResponseMessage;
                document.getElementById("form-fault").style.display = "block";

              }
              document.getElementById("iframePago").innerHTML = "";
              document.getElementById("iframePago").style.display = "none";
            } else {
              console.log("omar algo anda maaaal")
              console.log("data "+ data)
              document.getElementById("iframePago").innerHTML = "";
              document.getElementById("iframePago").style.display = "none";

              document.getElementById("form-error").style.display = "block";
              document.getElementById("trans-error-code").innerText = objEnvioPedido.nroInterno;
            }
          }
        );
      });
    };

    const volverCompletado = () => {
      router.push({ name: 'Home' });
    }
    const volverPayExpress = () => {
      borrarCarrito();
      cart.value = [];
      document.getElementById("buttonModalCloseStatic").style.display = "block";
      document.getElementById("buttonModalCloseStatic").click();
      document.getElementById("iframePago").style.display = "block";
      document.getElementById("form-complete").style.display = "none";
      document.getElementById("form-error").style.display = "none";
      document.getElementById("form-fault").style.display = "none";
      document.getElementById("iframePago").innerHTML = "";
      router.push({ name: 'Home' });
    }

    expose({ cargarCuotas });

    return {
      issuserParam,
      decimal,
      cuotas,
      cargarCuotas,
      styleClass,
      cuotaSelected,
      cargarPayExpress,
      volverCompletado,
      volverPayExpress
    };
  },
};
</script>

<style>
.text-dinamic {
  color: v-bind(styleClass) !important;
}

.text-card {
  margin-left: 3%;
  font-size: 80%;
}

.text-card-data {
  margin-left: 1%;
  font-size: 80%;
}
</style>