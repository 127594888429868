<template>
  <div class="form-check">
    <input class="form-check-input radio-checkout" type="radio" name="radioMediopago" 
      id="radioMedioPago_FE" />
      <img class="img-fluid" src="img/logo/fe.png" alt="Factura Express" />
  </div>
</template>

<script>
import { inject } from "vue";
import router from "@/router";

export default {
  name: "BuyFE",
  setup() {
    let cliente = inject("cliente");
    const validarCliente = inject("validarCliente");
    const postApiGeneric = inject("postApiGeneric");
    const precioEnvio = inject("precioEnvioCheckout");
    const issuserParam = inject("issuserParam");
    const products = inject("productsCheckout");
    const total = inject("totalCheckout");
    const computedTotal = inject("computedTotalCheckout");
    const pedidoFE = inject("pedidoFE");

    const generarPedido = () => {
      if (!validarCliente()) {
        return;
      }
      var carritoDD = [
        { variable: "moneda", variableValor: issuserParam.value.monedalp },
      ];
      var objEnvioPedido = {};
      objEnvioPedido.nombrePedido = cliente.nombre;
      objEnvioPedido.direccionPedido = cliente.direccion;
      objEnvioPedido.ciudadPedido = cliente.ciudad;
      objEnvioPedido.emailPedido = cliente.email;
      objEnvioPedido.telefonoPedido = cliente.telefono;
      objEnvioPedido.comentarioPedido = cliente.comentario;
      objEnvioPedido.documentoReceptor = cliente.documentoReceptor;
      objEnvioPedido.departamentoPedido = cliente.departamento;
      objEnvioPedido.tipoDocumento = cliente.tipoDocumentoReceptor;
      objEnvioPedido.idPrecioEnvio = precioEnvio.value.idPrecio;
      objEnvioPedido.carritoDD = carritoDD;
      objEnvioPedido.total = computedTotal.value;
      objEnvioPedido.totalSinCambios = total.value;

      // Swal.fire({
      //   title: "Generando Comprobante Factura Express",
      //   html: "Esto puede tardar unos segundos",
      //   onBeforeOpen: () => {
      //     Swal.showLoading();
      //   },
      // });
      postApiGeneric(
        "pedidos/generar",
        { objPedido: objEnvioPedido, listaCarritoCompras: products.value },
        (retorno) => {
            pedidoFE.value = retorno;
          if (retorno.cod_respuesta === "00") {
            // Swal.close();
            router.push({ name: "FeComplete" });
            return retorno;
          }
        }
      );
    };
    return { generarPedido };
  },
};
</script>

<style>
</style>