<template>
  <Navbar />
  <main class="pt-0 pt-md-0 pt-lg-0">
    <Slidebar />
    <div class="mb-5 mb-sm-0 mt-0 mt-sm-5">
      <router-view />
    </div>
  </main>
  <Foot />
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Slidebar from "@/components/SlideBar.vue";
import Foot from "@/components/Foot.vue";
import { provide, inject, readonly, ref, reactive } from "vue";
import Swal from "sweetalert2";
import { extraerCodigoScript, extraerValorPropiedad} from "@/composable/functions";


export default {
  title: "Market Express",
  components: {
    Navbar,
    Slidebar,
    Foot,
  },
  created() {
    // const darkmode = window.matchMedia("(prefers-color-scheme: dark)").matches;
    const visualModes = { ligth: "ligth-mode", dark: "dark-mode" };
    let visualMode = "ligth";
    if (localStorage.getItem("visualMode")) {
      visualMode = localStorage.getItem("visualMode");
    }
    document.body.classList.add(visualModes[visualMode]);
  },

  setup() {
    let decimal = 2;
    const categorias = ref([]);
    const tieneGruposCategorias = ref(0);
    const productos = ref({});
    const pagActive = ref(1);
    const $axios = inject("$axios");
    const urlApi = inject("URLAPI");
    const apiKey = inject("APIKEY");
    const appVersion = process.env.VUE_APP_VERSION
    const params = ref({
      search: "",
      categoria: -1,
      grupo: -1,
      start: 1,
      count: 24,
      orderby: 1,
    });
    const issuserParam = ref({});
    const cart = ref([]);
    const favList = ref([]);
    const styleClass = ref("var(--bs-success)");
    const navClientIcon = ref("var(--bs-success)");
    const navClientColor = ref("var(--bs-white)");
    const navCodigoColorIcono = ref("var(--bs-white)");
    const navsizeDefectoImagenTelefono = ref("200px");
    const navsizeNavBar = ref("1.5");
    const navsizeLogo = ref("50px");
    const navsizeDefectoImagen = ref("200px");
    const apiVersion = ref("");

    const FPixel = ref("");
    const EventoMC = ref("");
    const VDominio = ref("");


    let prProducto = {};
    let inicioHash = reactive({
      verProductos: { class: "active" },
      verFavoritos: { class: "" },
    });

    $axios
      .get(`${urlApi}/categorias/grupoCategoria`, {
        headers: {
          Authorization: `${apiKey}`,
        },
      })
      .then((result) => {
        let url = "categorias";
        tieneGruposCategorias.value = result.data.retorno.length;
        if (tieneGruposCategorias.value) {
          url += "/grupo";
        }
        $axios
          .get(`${urlApi}/${url}`, {
            headers: {
              Authorization: `${apiKey}`,
            },
          })
          .then((result) => {
            categorias.value = result.data.retorno;
          });
      });

    $axios
      .get(`${urlApi}/parametros`, {
        headers: {
          Authorization: `${apiKey}`,
        },
      })
      .then((result) => {

        issuserParam.value = result.data.retorno;

        styleClass.value = issuserParam.value.codigoColorPrincipal ? issuserParam.value.codigoColorPrincipal : "var(--bs-success)";
        navClientColor.value = issuserParam.value.codigoColorBarra ? issuserParam.value.codigoColorBarra : "var(--bs-white)";
        navCodigoColorIcono.value = issuserParam.value.codigoColorIcono ? issuserParam.value.codigoColorIcono : "var(--bs-white)";
        navClientColor.value = issuserParam.value.codigoColorBarra ? issuserParam.value.codigoColorBarra : "var(--bs-white)";
        navClientIcon.value = issuserParam.value.codigoColorIcono ? issuserParam.value.codigoColorIcono : "var(--bs-success)";
        navsizeDefectoImagenTelefono.value = issuserParam.value.sizeDefectoImagenTelefono ? issuserParam.value.sizeDefectoImagenTelefono + "px" : "200px";
        navsizeDefectoImagen.value = issuserParam.value.sizeDefectoImagen ? issuserParam.value.sizeDefectoImagen + "px" : "200px";
        navsizeLogo.value = issuserParam.value.SizeLogo ? issuserParam.value.SizeLogo + "px" : "50px";
        navsizeNavBar.value = issuserParam.value.SizeNavBar ? issuserParam.value.SizeNavBar : "1.5";
        apiVersion.value = issuserParam.value.apiVersion ? issuserParam.value.apiVersion : "1";
        document.title = issuserParam.value.nombreComercial ? issuserParam.value.nombreComercial : document.title;
        FPixel.value = result.data.retorno.FPixel ? result.data.retorno.FPixel : "";
        EventoMC.value = result.data.retorno.EventoMC ? result.data.retorno.EventoMC : "";
        VDominio.value = result.data.retorno.VDominio ? result.data.retorno.VDominio : "";

       
        setCodigosRedes();
      });

    const setCodigosRedes = () => {
      if (extraerCodigoScript(FPixel.value)) {
          const fbScript = document.createElement('script') 
          fbScript.innerHTML = extraerCodigoScript(FPixel.value);
          document.head.appendChild(fbScript);
        } 
        if ( EventoMC.value != "") {
          eval(EventoMC.value)
        }
        if(VDominio.value!=""){
          const metaEtiqueta = document.createElement("meta");
          const name = extraerValorPropiedad(VDominio.value, "name");
          const content = extraerValorPropiedad(VDominio.value, "content");
         
          metaEtiqueta.setAttribute("name", name);
          metaEtiqueta.setAttribute("content", content);
          document.head.appendChild(metaEtiqueta);
        }
    }

    const cargarProductos = async (params, fn) => {
      let url = `${urlApi}/productos`;

      if (issuserParam.value.tipoMarketTienda != null && issuserParam.value.tipoMarketTienda != 0) {
        url = `${urlApi}/productosTienda`;
        if (issuserParam.value.idDeposito > 0) {
          params.deposito = issuserParam.value.idDeposito;
        }
      } else {
        url = `${urlApi}/productos`;
        params.deposito = issuserParam.value.idDeposito;
      }
      $axios
        .get(url, {
          headers: {
            Authorization: `${apiKey}`,
          },
          params,
        })
        .then((result) => {
          productos.value = result.data.retorno;
          if (fn) {
            
            fn(result.data.retorno);
          }
        });

      return productos;
    };


    const getApiGeneric = (url, params, fn) => {
      return $axios
        .get(`${urlApi}/${url}`, {
          headers: {
            Authorization: `${apiKey}`,
          },
          params,
        })
        .then((result) => {
          if (fn) {
            fn(result.data);
          }
          return result.data;
        });
    };

    const postApiGeneric = (url, params, fn) => {
      return $axios
        .post(`${urlApi}/${url}`, params, {
          headers: {
            Authorization: `${apiKey}`,
          },
        })
        .then((result) => {
          if (fn) {
            fn(result.data);
          }
          return result.data;
        });
    };


    if (localStorage.getItem("product")) {
      //
      cart.value = JSON.parse(localStorage.getItem("product"));
    }

    const saveProducto = (prod, fn, saveLiteral) => {

      if (prod) {

        if (issuserParam.value.idDeposito >= 0 && prod.cantidad > prod.stock) {
          Swal.fire("No hay Stock Suficiente.", "", "warning");
          prod.cantidad = Math.floor(prod.stock) <= 0 ? 1 : Math.floor(prod.stock);
          prod.sinStock = true;
          return prod;
        }

        let index = -1;

        if (prod.tieneTalleColor || (issuserParam.value.tipoMarketTienda != null && issuserParam.value.tipoMarketTienda != 0)) {
          index = cart.value.findIndex(
            (product) =>
              product.id === prod.codigo
            //&& product.nombre === prod.nombreProducto
          );

        } else {
          index = cart.value.findIndex((product) => product.id === prod.codigo);
        }

        if (index >= 0) {
          let cantidadTotal = saveLiteral
            ? prod.cantidad
            : cart.value[index].cantidad + prod.cantidad;
          if (issuserParam.value.idDeposito >= 0 && cantidadTotal > prod.stock) {
            Swal.fire(
              "La cantidad del carrito supera la disponible.",
              "",
              "warning"
            );
            prod.cantidad = Math.floor(prod.stock);
            prod.carritoSuperiorStock = true;
            return prod;
          }
          cart.value[index].cantidad = cantidadTotal;
        } else {
          prProducto = { id: prod.codigo, cantidad: prod.cantidad };

          if (prod.tieneTalleColor) {
            prProducto.nombre = `${prod.nombreProducto} [${prod.nombreTalle} - ${prod.nombreColor}]`;
          }
          cart.value.push(prProducto);
        }
      }

      localStorage.setItem("product", JSON.stringify(cart.value));

      if (fn) {
        fn(cart);
      }
      return prod;
    };

    favList.value = localStorage.getItem("fav")
      ? JSON.parse(localStorage.getItem("fav"))
      : [];

    provide("decimal", decimal);
    provide("categorias", readonly(categorias));
    provide("tieneGruposCategorias", readonly(tieneGruposCategorias));
    provide("issuserParam", readonly(issuserParam));
    provide("productos", productos);
    provide("cargarProductos", cargarProductos);
    provide("paramsProductos", params);
    provide("pagActive", pagActive);
    provide("getApiGeneric", getApiGeneric);
    provide("postApiGeneric", postApiGeneric);
    provide("cart", cart);
    provide("urlAPI", urlApi);
    provide("pedidoFE", ref({}));
    provide("datos", ref({}));
    provide("productsComplete", ref({}));
    provide("objfactura", ref({}));
    provide("objEnvioPedido", ref({}));
    provide("saveProducto", saveProducto);
    provide("styleClass", styleClass);
    provide("inicioHash", inicioHash);
    provide("favList", favList);
    provide("app_version", appVersion);
    provide("api_version", apiVersion)
    return { styleClass, navClientColor, navClientIcon, navCodigoColorIcono, navsizeDefectoImagen, navsizeDefectoImagenTelefono, navsizeLogo, navsizeNavBar };
  },
};
</script>
<style lang="scss">
.dropdown-item.active {
  background-color: v-bind(styleClass) !important;
}

.dropdown-item:hover {
  background-color: v-bind(styleClass) !important;
}

.dark-mode .dropdown-item.active {
  color: var(--bs-dark) !important;
  background-color: v-bind(styleClass) !important;
}

.dark-mode .dropdown-item:hover {
  color: var(--bs-dark) !important;
  background-color: v-bind(styleClass) !important;
}

.text-client {
  color: v-bind(navClientIcon) !important;
}

.dark-mode .text-client {
  color: v-bind(navClientIcon) !important;
}

.bg-client {
  color: v-bind(navClientColor) !important;
  background-color: v-bind(navClientIcon) !important;
}

.dark-mode .bg-client {
  color: var(--bs-dark) !important;
  background-color: v-bind(navClientIcon) !important;
}

.btn-client {
  color: var(--bs-white) !important;
  background-color: v-bind(styleClass) !important;
}

.btn-client-invertido {
  color: v-bind(styleClass) !important;
  background-color: rgb(255, 255, 255) !important;
}

.btn-client-border {
  border-color: v-bind(styleClass) !important;
}

.bg-nav-client {
  background-color: v-bind(navClientColor) !important;
}

.dark-mode .bg-nav-client {
  background-color: #222 !important;
}

.dark-mode .btn-client {
  color: var(--bs-dark) !important;
  background-color: v-bind(styleClass) !important;
}

.bg-semi-black-transparent {
  background-color: #0000005b !important;
}

.bg-semi-black-transparent .nav-link:hover {
  background-color: #00000083 !important;
}

.card-hover:hover {
  background-color: #0000005b !important;
}

.btn-buscar {
  color: v-bind(navCodigoColorIcono) !important;
}

.client-navbar {
  line-height: v-bind(navsizeNavBar) !important;
}

.client-size-logo {
  height: v-bind(navsizeLogo) !important;

}


@media(min-width: 576px) {
  .img-escritorio {
    height: v-bind(navsizeDefectoImagen) !important;
  }

  .padding {
    padding-bottom: 20% !important;
  }
}
@media(min-width: 980px){
  .padding {
    padding-bottom: 5% !important;
  }
}
@media(min-width: 1389px) {
  #carouselDynamic{
    height: auto!important;
    margin-top: -10px!important;
  }
}
@media(max-width: 1389px) {
  #carouselDynamic{
    height: auto!important;
    padding-top: 0px!important;
  }
}


@media(max-width: 574px) {
   .img-telefono {
    height: v-bind(navsizeDefectoImagenTelefono) !important;
  } 

   #carouselDynamic img {
    height: auto!important;
  } 
  #carouselDynamic{
    height: auto!important;
    padding-top: 60px!important;
  }


  .padding {
    padding-bottom: 25% !important;
  }

}

.btn-redsocial-footer {
  margin-bottom: 13%;
}
</style>
