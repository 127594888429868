<template>
  <div class="row pt-5">
                <div class="row">
                    <div class="col text-center">
                        <h3>Pago correcto</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Producto</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(article, index) in colCarrito" :key="index">
                                        <th scope="row">
                                            {{article.nombreProducto}} <strong class="product-quantity"> x {{article.cantidad}}</strong>
                                        </th>
                                        <th scope="row">
                                            <span class="amount">{{formatMoney(issuserParam.monedalp)}} {{formatPrice(article.precioVenta, decimal)}}</span>
                                        </th>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>Total</th>
                                        <th>{{formatMoney(issuserParam.monedalp)}} {{formatPrice(computedTotal,decimal)}}</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-4">
                        <h5>Información Cliente:</h5>
                        <p>Nombre: {{pedido.nombrePedido}}</p>
                        <p>Dirección: {{pedido.direccionPedido}}</p>
                        <p>Ciudad: {{pedido.ciudadPedido}}</p>
                        <p>Email: {{pedido.emailPedido}}</p>
                        <p>Comentario: {{pedido.comentarioPedido}}</p>
                    </div>
                    <div class="col-12 col-md-4">
                        <h5>Información:</h5>
                        <p>Estado: Aprobado</p>
                        <p>Tipo de pago: {{pedidoFE.Tarjeta}}</p>
                        <p>Id orden Pago Web: {{pedidoFE.Order}}</p>
                        <p>Purchase Id: {{pedidoFE.PurchaseId}}</p>
                        <p>Transacción Id: {{pedidoFE.TransactionID}}</p>
                    </div>
                    <div class="col-12 col-md-4">
                        <h5>Documento Electrónico Factura Express</h5>
                        <p>Tipo Comprobante: {{pedidoFE.tipoComprobante + "-" + tipoComprobantes(pedidoFE.tipoComprobante)}}</p>
                        <p>Serie: {{pedidoFE.serie}}</p>
                        <p>Numero: {{pedidoFE.numero}}</p>
                        <p>Link: <a :href="factura.linkDocumento" class="link_FE" target="_blank">Ver Factura</a></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="order-button-payment">
                            <a href="javascript:void(0);" @click="continueShopping" class="btn btn-outline-primary"><fa icon="shopping-cart" /> Continuar Comprando</a>
                        </div>
                    </div>
                </div>
        </div>
</template>

<script>
import { inject, ref, watch, computed } from "vue";
import {tipoComprobantes}  from "@/composable/functions";
import router from "@/router";
export default {
  setup() {
    const decimal = inject("decimal");
    const pedidoFE = inject("pedidoFE");
    const issuserParam = inject("issuserParam");
    const factura = ref({});
    const pedido = ref({});
    const colCarrito = ref([]);

    const computedTotal = computed(()=>{
        return parseFloat(colCarrito.value.reduce((acc, curr) =>{
          return acc+parseFloat(curr.subTotal);
      }, 0));
    });

    const instanceSiemprePago = () => {
        if (pedidoFE.value.pedido){
            factura.value = pedidoFE.value.factura;
            pedido.value = pedidoFE.value.pedido;
            colCarrito.value = pedido.value.colCarrito;
        }else{
            router.push({name:"Home"});
        }
    };

    if (issuserParam.value.linkFE) {
      instanceSiemprePago();
    }

    watch(
      () => issuserParam.value,
      (newParams) => {
        if (!newParams) return;
        instanceSiemprePago();
      }
    );

    const continueShopping = () => {
        router.push({name:"Home"});
    }

    return {
      issuserParam,
      factura,
      pedido,
      colCarrito,
      pedidoFE,
      decimal,
      computedTotal,
      tipoComprobantes,
      continueShopping
    };
  },
};
</script>

<style>
</style>