export default {
    methods: {
        formatMoney(lp) {
            const hashMoneys = { UYU: '$', USD: 'U$S', BRL: 'R$' }
            return hashMoneys[lp];
        },
        calcSalePrice(price, discount, decimal) {
            price = parseFloat(price);
            discount = parseFloat(discount);
            return parseFloat(price - (discount / 100 * price)).toFixed(decimal);
        },
        formatPrice(price, decimal) {
            price = parseFloat(price);
            // const fraction = new Intl.NumberFormat('de-DE', {
            //     minimumFractionDigits: 0,
            //     maximumFractionDigits: 0,
            // });

            const formatter = new Intl.NumberFormat('de-DE', {
                minimumFractionDigits: decimal,
            });

            return formatter.format(price);
        }
    }
}