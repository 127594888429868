import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Cart from '../views/Cart.vue'
import DetailArticle from '../views/DetailArticle.vue'
import Checkout from '../views/Checkout.vue'
import MpAprobado from '../views/MpAprobado.vue'
import MpError from '../views/MpError.vue'
import MpPendiente from '../views/MpPendiente.vue'
import FeComplete from '../views/FeComplete.vue'
import CompletePayExpress from '../views/CompletePayExpress.vue'
import pagoWebAprobado from '../views/pagoWebAprobado.vue'
import pagoWebError from '../views/pagoWebError.vue'
import Catalog from '../views/Catalog.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart
  },
  {
    path: '/detail/:id',
    name: 'DetailArticle',
    component: DetailArticle
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/mpAprobado',
    name: 'MpAprobado',
    component: MpAprobado
  },
  {
    path: '/mpError',
    name: 'MpError',
    component: MpError
  },
  {
    path: '/mpPendiente',
    name: 'MpPendiente',
    component: MpPendiente
  },
  {
    path: '/feComplete',
    name: 'FeComplete',
    component: FeComplete
  },
  {
    path: '/completePayExpress',
    name: 'CompletePayExpress',
    component: CompletePayExpress
  },
  {
    path: '/pagoWebAprobado',
    name: 'PagoWebAprobado',
    component: pagoWebAprobado
  },
  {
    path: '/pagoWebError',
    name: 'PagoWebError',
    component: pagoWebError
  },
  {
    path: '/catalogo/',
    name: 'Catalog',
    component: Catalog
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
