<template>
  <div class="row border-top border-2 mt-3">
    <div class="col text-center mt-3">
      <h5 >Artículos más vendidos</h5>
    </div>
  </div>
  <div class="row">
    <div class="col ml-1">
      <Carousel
        :settings="settings"
        :breakpoints="breakpoints"
        :wrap-around="true"
        :autoplay="3000"
        class="carouselMostBuy"
      >
        <Slide
          v-for="producto in productos"
          :key="producto.codigo"
          class="px-0 px-sm-1 card-group my-5"
        >
          <div class="carousel__item">
            <article-product :product="producto" class="mx-2" styleImage="max-width:300px;"/>
          </div>
        </Slide>

        <template #addons>
          <Navigation />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { watch, inject, ref } from "vue";

import { Carousel, Navigation, Slide } from "vue3-carousel";

import ArticleProduct from "@/components/ArticleProduct.vue";

export default {
  components: {
    Carousel,
    Slide,
    Navigation,
    ArticleProduct,
  },
  data: () => ({
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      500: {
        itemsToShow: 2,
        snapAlign: "center",
      },
      // 1024 and up
      765: {
        itemsToShow: 3.5,
        snapAlign: "center",
      },
      1200: {
        itemsToShow: 4.5,
        snapAlign: "center",
      },
    },
  }),
  setup() {
    const productos = ref([]);
    const issuserParam = inject("issuserParam");
    const getApiGeneric = inject("getApiGeneric");
    const styleClass = inject("styleClass");

    const instanceMostBuyArticles = () => {
      let params = { count: 10 };
      let url = `productos/vendidos`;
      if (issuserParam.value.idDeposito >= 0) {
        url = `productosStock/vendidos`;
        params.deposito = issuserParam.value.idDeposito;
      }
      getApiGeneric(url, params, (data) => {
        // console.log("coso 3: " + JSON.stringify(data.retorno))
        productos.value = data.retorno;
      });
    };

    if (issuserParam.value.idLP) {
      instanceMostBuyArticles();
    }

    watch(
      () => issuserParam.value,
      (newParams) => {
        if (!newParams) return;
        instanceMostBuyArticles();
      }
    );

    return {
      productos,
      styleClass,
    };
  },
};
</script>

<style lang="scss">
.carousel__slide>.carousel__item {
  transform: scale(1);
  opacity: 0.5;
  transition: 0.5s;
}

.carousel__slide--visible>.carousel__item {
  opacity: 1;
  transform: rotateY(0);
}

.carousel__slide--next>.carousel__item {
  transform: scale(0.9) translate(-10px);
}

.carousel__slide--prev>.carousel__item {
  transform: scale(0.9) translate(10px);
}

.carousel__slide--active>.carousel__item {
  transform: scale(1.1);
}

.carouselMostBuy .carousel__prev,
.carouselMostBuy .carousel__next {
  box-sizing: content-box;
  background-color: v-bind(styleClass);
  border: 5px solid white;
}

.dark-mode .carouselMostBuy .carousel__prev,
.dark-mode .carouselMostBuy .carousel__next {
  box-sizing: content-box;
  background-color: v-bind(styleClass);
  border: 5px solid #181818;
}
@media(max-width: 450px) {
  .carousel__item {
    max-width: 260px !important;
    min-width: 260px !important;
  }
}

</style>