<template>
  <div class="container padding pb-md-5 mt-md-0 mt-lg-0 pt-md-3 pt-lg-3">
    <div class="row text-center mt-5">
      <div class="col mt-5 mt-md-2 mt-lg-2">
        <h2>Confirmar Compra</h2>
      </div>
    </div>
    <div class="row px-md-5">
      <div class="col-12 col-sm-12 col-md-6 mt-2">
        <div class="card border-0">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <label for="tipoDocumentoReceptor" class="form-label mb-0">Tipo Cliente <span
                    class="required">*</span></label>
                <select class="form-select" v-model="cliente.tipoDocumentoReceptor">
                  <option value="4">Otros - Sin Especificar</option>
                  <option value="2">RUT - Empresas Uruguayas</option>
                  <option value="3">CI - Cedula Uruguaya</option>
                </select>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <label for="documentoReceptor" class="form-label mb-0">Documento <span class="required">*</span></label>
                <input type="text" class="form-control" maxlength="20" placeholder="Documento"
                  v-model="cliente.documentoReceptor" />
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <label for="nombre" class="form-label mb-0">Nombre <span class="required">*</span></label>
                <input type="text" class="form-control" maxlength="50" placeholder="Nombre" v-model="cliente.nombre"  />
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <label for="direccion" class="form-label mb-0">Dirección <span class="required">*</span></label>
                <input type="text" class="form-control" maxlength="50" placeholder="Dirección"
                  v-model="cliente.direccion" />
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <label for="ciudad" class="form-label mb-0">Ciudad <span class="required">*</span></label>
                <input type="text" class="form-control" maxlength="50" placeholder="Ciudad" v-model="cliente.ciudad" />
              </div>
              <div class="col">
                <label for="departamento" class="form-label mb-0">Departamento <span class="required">*</span></label>
                <input type="text" class="form-control" maxlength="50" placeholder="Departamento"
                  v-model="cliente.departamento" />
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <label for="email" class="form-label mb-0">Email de Contacto <span class="required">*</span></label>
                <input type="email" class="form-control" maxlength="50" placeholder="Email" v-model="cliente.email" />
              </div>
              <div class="col">
                <label for="telefono" class="form-label mb-0">Teléfono <span class="required">*</span></label>
                <input type="text" class="form-control" maxlength="50" placeholder="Teléfono"
                  v-model="cliente.telefono" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <div class="form-floating">
                  <textarea v-model="cliente.comentario" class="form-control" maxlength="100" style="height: 100px"
                    placeholder="Comentarios adicionales."></textarea>
                  <label for="comentarioPedido">Comentarios</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 mt-2">
        <div class="card border-0">
          <div class="card-body">
          </div>
          <ul class="list-group list-group-flush" id="ulTotales">
            <li class="list-group-item" v-for="product in products" :key="product.codigo">
              <div class="row">
                <div class="col-8">
                  {{ product.nombreProducto }}
                  <span class="fw-bold">X {{ product.cantidad }}</span>
                </div>
                <div class="col text-end">
                  {{ formatMoney(issuserParam.monedalp) }}
                  {{ formatPrice(product.precioVenta, decimal) }}
                </div>
              </div>
            </li>
            <li class="list-group-item" v-if="adjust10">
              <div class="row">
                <div class="col-8">Ajuste Totales x 1</div>
                <div class="col text-end">
                  $ {{ formatPrice(adjust10, decimal) }}
                </div>
              </div>
            </li>
          </ul>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="row">
                <div class="col">
                  <h4>Total:</h4>
                </div>
                <div class="col text-end">
                  <h4 class="fw-bold" id="totalPedidovisible">
                    {{ formatMoney(issuserParam.monedalp) }}
                    {{ formatPrice(computedTotal, decimal) }}
                  </h4>
                </div>
              </div>
            </li>
          </ul>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <div class="row">
                <div class="col-12">
                  <h5>Datos Bancarios</h5>
                </div>
                <div class="col-12">
                  <p>{{ issuserParam.datosBancarios }}</p>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col-12">
                  <h5>Envío
                    <fa icon="truck" />
                  </h5>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-8">
                      <div class="form-check">
                        <input class="form-check-input radio-checkout" type="radio" name="radioenvio" id="radio_-1"
                          :value="{ idPrecio: '-1', precio: 0 }" v-model="precioEnvio" checked />
                        <label class="form-check-label" for="radio_-1">
                          Acordar Con el Vendedor
                        </label>
                      </div>
                    </div>
                    <div class="col text-end">
                      <fa icon="truck" /> -
                    </div>
                  </div>
                  <div class="row" v-for="(precio, index) in precios" :key="precio.idPrecio">
                    <div class="col-8">
                      <div class="form-check">
                        <input class="form-check-input radio-checkout" type="radio" name="radioenvio"
                          :id="[`radio_${index}`]" :value="precio" v-model="precioEnvio" />
                        <label class="form-check-label" :for="[`radio_${index}`]">
                          {{ precio.descripcion }}
                        </label>
                      </div>
                    </div>
                    <div class="col text-end">
                      <fa icon="truck" />
                      {{
                        precio.precio
                          ? `${formatMoney(
                            issuserParam.monedalp
                          )} ${formatPrice(precio.precio, decimal)}`
                          : "Gratis"
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col">
                  <h4 v-if="issuserParam.pedidossinmediodepago != '2'">Generar Pedido:</h4>
                  <div v-if="issuserParam.pedidossinmediodepago != '2'" class="d-grid col-5 col-sm-2">
                    <BuyFE />
                  </div>
                  <h4 v-if="sellosPayExpress.length || issuserParam.emiteCompraSN === 'S'">Pagar con:</h4>
                </div>
              </div>
              <div class="row">
                <div class="d-grid col-5 col-sm-2" v-if="issuserParam.emiteCompraSN === 'S'">
                  <BuyMercadoPago />
                </div>
                <loading-data v-if="!loadingData.payExpress && !loadingData.siemprePago"
                  msg="Cargando medios de pago" />
                <div class="d-grid col-5 col-sm-2" v-for="payExpress in sellosPayExpress" :key="payExpress.tipo">
                  <div class="form-check">
                    <input class="form-check-input radio-checkout" type="radio" name="radioMediopago"
                      :id="[`radioMedioPago_${payExpress.sello}`]" />
                    <button type="button" class="
                      btn btn-outline-light
                      btnPagarPayExpressPrevia
                      border-0" tipo="" pasarela="" cuotas="">
                      <img class="img-fluid sellos" :src="payExpress.logo" style="min-width: 50px!important;" :alt="payExpress.sello" />
                    </button>
                  </div>
                </div>

                <div class="d-grid col-6 col-sm-3" v-for="siemprePago in sellosSiemprePago" :key="siemprePago.idSello">
                  <button type="button" class="btn btn-outline-light btnFacturarPagoWeb border-0"
                    @click="loadSiemprePago(siemprePago)">
                    <img class="img-fluid w-100" :src="siemprePago.logo" :alt="siemprePago.sello" />
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div class="card-body mb-5">
            <div class="row mb-5">
              <div class="d-grid col-12 col-sm-6 text-center mt-1">
                <button class="btn btn-client-invertido btn-client-border" @click="continuarComprando">
                  <fa icon="cart-arrow-down" /> Continuar Comprando
                </button>
              </div>
              <div class="d-grid col-12 col-sm-6 text-center mt-1">
                <button class="btn btn-client" @click="validarmediopago(sellosPayExpress)">
                  <fa icon="shopping-cart" /> Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SiemprePago ref="childSiemprePagoRef" />
    <PayExpress ref="childComponentRef" />
  </div>
</template>

<script>
import { inject, reactive, ref, watch, computed, provide, onMounted } from "vue";
// import { useRoute } from "vue-router";
import {
  validarCedula,
  validarRuc,
  validateSendMails,
  validarInputNombreCheckout,
  validarInputsCheckout,
  validarTelefonoCheckout,
  formatPrice
} from "@/composable/functions";
import Swal from "sweetalert2";
import router from "@/router";
// import { Modal } from "bootstrap";

import {
  calcSalePrice,
  calcSubTotal,
  adjust10th,
} from "@/composable/functions";

import BuyMercadoPago from "@/components/BuyMercadoPago.vue";
import BuyFE from "@/components/BuyFE.vue";
import PayExpress from "@/components/PayExpress.vue";
import SiemprePago from "@/components/SiemprePago.vue";
import LoadingData from "@/components/LoadingData.vue";

export default {
  components: {
    BuyMercadoPago,
    BuyFE,
    PayExpress,
    SiemprePago,
    LoadingData
  },
  setup() {
    const productsComplete = inject("productsComplete");
    const postApiGeneric = inject("postApiGeneric");
    const pedidoFE = inject("pedidoFE");
    const decimal = inject("decimal");
    const cart = inject("cart");
    const getApiGeneric = inject("getApiGeneric");
    // const postApiGeneric = inject("postApiGeneric");
    const issuserParam = inject("issuserParam");
    const products = ref([]);
    const total = ref(0);
    const adjust10 = ref(0);
    const precios = ref([]);
    // const route = useRoute();
    const precioEnvio = ref({ idPrecio: "-1", precio: 0 });
    const computedTotal = computed(() =>
      parseFloat(total.value + precioEnvio.value.precio)
    );
    const sellosPayExpress = ref([]);
    const sellosSiemprePago = ref([]);
    const payExpressSelected = ref({});
    const siemprePagoSelected = ref({});
    const loadingData = reactive({
      payExpress: false,
      siemprePago: false
    });


    let cliente = reactive({
      tipoDocumentoReceptor: "4",
      documentoReceptor: "",
      nombre: "",
      direccion: "",
      ciudad: "",
      departamento: "",
      email: "",
      telefono: "",
      comentario: "",
    });
    const instanceProducto = (product, index) => {
      getApiGeneric(
        "productos/detalle",
        { producto: product.id, lp: issuserParam.value.idLP },
        (prod) => {
          prod.retorno.index = index;
          prod.retorno.cantidad = product.cantidad;
          prod.retorno.nombreProducto = product.nombre ? product.nombre : prod.retorno.nombreProducto;
          prod.retorno.precioVenta =
            parseFloat(
              calcSalePrice(
                prod.retorno.precioUnitario,
                prod.retorno.pctDescuento,
                decimal
              )
            ) +
            adjust10th(
              issuserParam.value.configRedondeoDecimas,
              calcSalePrice(
                prod.retorno.precioUnitario,
                prod.retorno.pctDescuento,
                decimal
              ),
              decimal
            );
          prod.retorno.subTotal = calcSubTotal(
            prod.retorno.precioVenta,
            prod.retorno.cantidad,
            decimal
          );
          total.value += parseFloat(prod.retorno.subTotal);
          products.value.push(prod.retorno);
          products.value.sort((a, b) => {
            return a.index < b.index;
          });
          adjust10.value = adjust10th(
            issuserParam.value.configRedondeoDecimas,
            total.value,
            decimal
          );
          total.value += adjust10.value;
        }
      );
    };

    const instancePrecios = () => {
      getApiGeneric("precios", null, (price) => {
        precios.value = price.retorno;
      });
    };

    const instancePasarelas = () => {
      getApiGeneric("pasarelas/payExpress", null, (pasarela) => {
        sellosPayExpress.value = pasarela.retorno;
        loadingData.payExpress = true;
      });
      getApiGeneric("pasarelas/siemprePago", null, (pasarela) => {
        sellosSiemprePago.value = pasarela.retorno;
        loadingData.siemprePago = true;
      });
    };

    const instanceAll = () => {
      cart.value.forEach(instanceProducto);
      instancePrecios();
      instancePasarelas();
    };

    if (issuserParam.value.idLP) {
      instanceAll();
    }

    watch(
      () => issuserParam.value,
      (newParams) => {
        if (!newParams) return;
        instanceAll();
      }
    );

    const calcTotal = () => {
      total.value = products.value.reduce((acc, curr) => {
        return acc + parseFloat(curr.subTotal);
      }, 0);
      return total.value;
    };

    const validarCliente = () => {
      // const external_reference = route.query.external_reference;

      if (
        cliente.tipoDocumentoReceptor === "3" &&
        cliente.documentoReceptor.trim() === ""
      ) {
        Swal.fire("Debe ingresar cédula", "", "warning");
        return false;
      }
      if (
        cliente.tipoDocumentoReceptor === "3" &&
        !validarCedula(cliente.documentoReceptor.trim())
      ) {
        Swal.fire(
          "La cédula ingresada no es correcta, la misma no debe contener puntos ni guiones.",
          "",
          "warning"
        );
        return false;
      }
      if (
        cliente.tipoDocumentoReceptor === "2" &&
        cliente.documentoReceptor.trim() === ""
      ) {
        Swal.fire("Debe ingresar RUT.", "", "warning");
        return false;
      }
      if (
        cliente.tipoDocumentoReceptor === "2" &&
        validarRuc(cliente.documentoReceptor.trim()) === false
      ) {
        Swal.fire(
          "El RUT ingresado no es correcto, el mismo no debe contener puntos ni guiones.",
          "",
          "warning"
        );
        return false;
      }
      console.log("clente nombre " + cliente.nombre);
      if (cliente.nombre.trim() === "") {
        Swal.fire("Debe ingresar nombre.", "", "warning");
        return false;
      }
      if (!validarInputNombreCheckout(cliente.nombre.trim())) {
        Swal.fire("El nombre no puede contener caracteres especiales ni numeros.", "", "warning");
        return false;
      }

      if (cliente.direccion.trim() === "") {
        Swal.fire("Debe ingresar dirección.", "", "warning");
        return false;
      }
      if (!validarInputsCheckout(cliente.direccion.trim())) {
        Swal.fire("Debe ingresar dirección válida.", "", "warning");
        return false;
      }

      if (cliente.ciudad.trim() === "") {
        Swal.fire("Debe ingresar ciudad.", "", "warning");
        return false;
      }

      if (!validarInputsCheckout(cliente.ciudad.trim())) {
        Swal.fire("Debe ingresar ciudad válida.", "", "warning");
        return false;
      }
      //Hago esto porque baiz pidio esta validacion y se me solicito dejarlo solo para Baiz
      //inc 2022-01630 - MarketExpress mejoras graficas solicitadas por baiz/quitar Telefono obligatorio para Baiz
      //if (cliente.telefono.trim() === "" && datas[0] == 1341960) {
      //  Swal.fire("Debe ingresar ciudad.", "", "warning");
      //  return false;
      // }
      if (
        cliente.email.trim() === "" ||
        !validateSendMails(cliente.email.trim()).valid
      ) {
        Swal.fire("Debe ingresar email o email no es correcto.", "", "warning");
        return false;
      }
      if (cliente.telefono.trim() === "") {
        Swal.fire("Debe ingresar teléfono.", "", "warning");
        return false;
      }
      if (!validarTelefonoCheckout(cliente.telefono.trim())) {
        Swal.fire("Debe ingresar un teléfono válido y sin espacios", "", "warning");
        return false;
      }
      if (cliente.departamento.trim() === "") {
        Swal.fire("Debe ingresar Departamento.", "", "warning");
        return false;
      }
      if (!validarInputsCheckout(cliente.departamento.trim())) {
        Swal.fire("Debe ingresar Departamento correcto.", "", "warning");
        return false;
      }
      return true;
    };


    const childComponentRef = ref();

    const validarmediopago = (sellosPayExpress) => {
      let objMediopago;
      let idRadio;
      let continuarSN = 'N';
      if (!validarCliente()) {
        return;
      }
      if (computedTotal.value <= 0) {
        Swal.fire("Agregue un articulo al carrito para completar la compra.", "", "warning");
        return;
      }
      if (document.getElementById('radioMedioPago_MercadoPago')?.checked) {
        facturarMercadoPago();
      } else if (document.getElementById('radioMedioPago_FE')?.checked) {
        generarPedido();
      } else {

        for (let i = 0; i < sellosPayExpress.length; i++) {
          idRadio = 'radioMedioPago_' + sellosPayExpress[i].sello;
          if (document.getElementById(idRadio).checked) {
            objMediopago = sellosPayExpress[i];
            continuarSN = 'S';
            break
          }
          else if (i == (sellosPayExpress.length - 1)) {
            Swal.fire("Seleccione Medio de pago", "", "warning");
            break
          }
        }
        if (continuarSN == 'S') {
          loadPayExpress(objMediopago);
          productsComplete.value = products;
        }
      }
    }

    const generarPedido = () => {
      if (!validarCliente()) {
        return;
      }
      var carritoDD = [
        { variable: "moneda", variableValor: issuserParam.value.monedalp },
      ];
      var objEnvioPedido = {};
      objEnvioPedido.nombrePedido = cliente.nombre;
      objEnvioPedido.direccionPedido = cliente.direccion;
      objEnvioPedido.ciudadPedido = cliente.ciudad;
      objEnvioPedido.emailPedido = cliente.email;
      objEnvioPedido.telefonoPedido = cliente.telefono;
      objEnvioPedido.comentarioPedido = cliente.comentario;
      objEnvioPedido.documentoReceptor = cliente.documentoReceptor;
      objEnvioPedido.departamentoPedido = cliente.departamento;
      objEnvioPedido.tipoDocumento = cliente.tipoDocumentoReceptor;
      objEnvioPedido.idPrecioEnvio = precioEnvio.value.idPrecio;
      objEnvioPedido.carritoDD = carritoDD;
      objEnvioPedido.total = computedTotal.value;
      objEnvioPedido.totalSinCambios = total.value;

      // Swal.fire({
      //   title: "Generando Comprobante Factura Express",
      //   html: "Esto puede tardar unos segundos",
      //   onBeforeOpen: () => {
      //     Swal.showLoading();
      //   },
      // });
      postApiGeneric(
        "pedidos/generar",
        { objPedido: objEnvioPedido, listaCarritoCompras: products.value },
        (retorno) => {
          pedidoFE.value = retorno;
          if (retorno.cod_respuesta === "00") {
            // Swal.close();
            router.push({ name: "FeComplete" });
            return retorno;
          }
        }
      );
    };

    const facturarMercadoPago = () => {
      if (!validarCliente()) {
        return;
      }
      var carritoDD = [
        { variable: "moneda", variableValor: issuserParam.value.monedalp },
      ];
      var objEnvioPedido = {};
      objEnvioPedido.nombrePedido = cliente.nombre;
      objEnvioPedido.direccionPedido = cliente.direccion;
      objEnvioPedido.ciudadPedido = cliente.ciudad;
      objEnvioPedido.emailPedido = cliente.email;
      objEnvioPedido.telefonoPedido = cliente.telefono;
      objEnvioPedido.comentarioPedido = cliente.comentario;
      objEnvioPedido.documentoReceptor = cliente.documentoReceptor;
      objEnvioPedido.departamentoPedido = cliente.departamento;
      objEnvioPedido.tipoDocumento = cliente.tipoDocumentoReceptor;
      objEnvioPedido.idPrecioEnvio = precioEnvio.value.idPrecio;
      objEnvioPedido.carritoDD = carritoDD;
      objEnvioPedido.total = computedTotal.value;
      objEnvioPedido.totalSinCambios = total.value;

      Swal.fire({
        title: "Enviando a Mercado Pago",
        html: "Esto puede tardar unos segundos",
        didOpen: () => {
          Swal.showLoading();
          postApiGeneric(
            "mercadoPago/facturar",
            { objPedido: objEnvioPedido, listaCarritoCompras: products.value },
            (retorno) => {
              if (retorno.Cod === 200) {
                window.location.replace(retorno.Url);
                return retorno;
              }
            }
          );
        },
      }).then((result) => {
        if (result.dismiss) {
          Swal.fire({
            title: `Confirme su compra`,
          });
        }
      });
    };


    const loadPayExpress = (payExpress) => {
      payExpressSelected.value = payExpress;
      childComponentRef.value.cargarCuotas((modal) => {
        modal.show();
      });
    }

    const childSiemprePagoRef = ref();
    const loadSiemprePago = (siemprePago) => {
      if (!validarCliente()) {
        return;
      }
      siemprePagoSelected.value = siemprePago;
      childSiemprePagoRef.value.cargarModal((modal) => {
        modal.show();
      });
    }

    const continuarComprando = () => {
      router.push({ name: 'Home' });
    }

    onMounted(() => {
      window.scrollTo(0, 0);
    });

    provide("computedTotalCheckout", computedTotal);
    provide("totalCheckout", total);
    provide("productsCheckout", products);
    provide("precioEnvioCheckout", precioEnvio);
    provide("cliente", cliente);
    provide("validarCliente", validarCliente);
    provide("payExpressSelected", payExpressSelected);
    provide("siemprePagoSelected", siemprePagoSelected);

    return {
      cart,
      cliente,
      calcTotal,
      products,
      total,
      issuserParam,
      adjust10,
      precios,
      precioEnvio,
      computedTotal,
      sellosPayExpress,
      sellosSiemprePago,
      decimal,
      loadPayExpress,
      validarmediopago,
      childComponentRef,
      loadSiemprePago,
      childSiemprePagoRef,
      continuarComprando,
      loadingData,
      formatPrice
    };
  },
};
</script>
<style>
@media(min-width: 576px){
  .sellos{
    min-width: 50px!important;
  } 
}

</style>