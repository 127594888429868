<template>
    <button class="btn btn-default btn-sm font-weight-bold" style="max-width: 80px;" @click="volver"><fa  icon="arrow-left" /> volver</button>
</template>
  
  <script>
  export default {
    methods: {
      volver() {
        window.history.back();
      }
    }
  };
  </script>