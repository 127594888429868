<template>
  <div class="row">
  <div class="col text-center">
    <div class="row" :class="classes">
      <div class="col">
        <img src="/img/loading.gif" height="50" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h5>{{msg}}</h5>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name:'loading-data',
  props: {
      msg: String,
      classes: String,
    }
  }
</script>

<style>

</style>