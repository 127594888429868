<template>
  <header>
    <nav class="navbar d-block client-navbar navbar-expand-lg bg-transparent mb-0 pt-0 pb-0 flex-column "
      :class="[fixed]">
      <div class="container-fluid justify-content-center mb-0 bg-nav-client">
        <router-link to="/" class="navbar-brand px-0 mx-0" aria-current="page" id="logo">
          <img :src="issuserParam.rutaLogo + issuserParam.logo" class="client-size-logo" />
        </router-link>
        <div class="d-flex align-items-center col-4">
          <a href="#offcanvasExample" class="btn text-client d-md-block d-lg-block d-none" id="offcanvasId"
            data-bs-toggle="offcanvas" role="button" aria-controls="offcanvasExample">
            <fa icon="bars" class="d-block mx-auto" :class="[sizeIcon]" />
            Filtros
          </a>
          <div class="nav-item d-md-none d-lg-none d-block">
            <router-link to="/cart" class="
                    nav-link
                    text-client
                    border-start-0
                    rounded-0
                  " v-slot="{ isActive, isExactActive }">
              <p class="mb-0 mt-2" v-if="issuserParam.deshabilitarCarrito != 'S'" :class="
                isActive && isExactActive ? 'text-secondary ' : 'text-client'
              ">
                <fa icon="shopping-cart" class="bi d-block mx-auto" :class="[sizeIcon]" />
                Carrito
                <span class="
                        position-absolute
                        translate-middle
                        badge
                        rounded-pill
                        bg-client
                    
                      " style="top: 0.7rem !important" v-if="cart.length">
                  {{ cart.length }}
                  <span class="visually-hidden">En el carrito</span>
                </span>
              </p>
            </router-link>
          </div>
          <div class="justify-content-end border-0 ms-auto" id="contenedornumeroscabezal"
            v-if="(issuserParam.whatsapp || issuserParam.telefono) && (issuserParam.ocultarDatosCabezal == 1)">
            <a :href="['https://wa.me/598' + whatsapp.trim()]"
              v-for="(whatsapp, index) in issuserParam.whatsapp.includes('/') ? issuserParam.whatsapp.split('/') : []"
              class="text-client" :key="index" target="_blank">
              <fa :icon="['fab', 'whatsapp']" />
              {{ whatsapp.trim() }}
            </a>
            <a :href="['https://wa.me/598' + issuserParam.whatsapp]"
              v-if="issuserParam.whatsapp && !issuserParam.whatsapp.includes('/')" class="text-client" target="_blank">
              <fa :icon="['fab', 'whatsapp']" v-if="issuserParam.whatsapp" />
              {{ issuserParam.whatsapp }}
            </a>
            &nbsp;
            <a class="text-client" v-for="(telefono, index) in issuserParam.telefono.includes('/')
              ? issuserParam.telefono.split('/')
              : []" :key="index" :href="['tel:' + telefono.trim()]">
              <fa icon="phone" /> {{ telefono.trim() }}&nbsp;
            </a>
            <a :href="['tel:' + issuserParam.telefono]" v-if="
              issuserParam.telefono && !issuserParam.telefono.includes('/')
            " class="text-client">
              <fa icon="phone" /> {{ issuserParam.telefono }}
            </a>
          </div>
          <button class="navbar-toggler justify-content-end border-0 ms-auto px-0" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <fa icon="list" class="d-block mx-auto" :class="[sizeIcon]" />
            Ver
          </button>
        </div>
        <div class="collapse navbar-collapse pb-1" id="navbarSupportedContent">
          <ul class="nav col-12 justify-content-center justify-content-lg-end mt-md-0 text-small
              " style="margin-bottom: -1rem">
            <div class="col-6 mt-2 d-none d-md-block d-lg-block">
              <div class="input-group ">
                <input type="text" class="form-control" placeholder="Buscar..." aria-label="Buscar"
                  aria-describedby="button-addon2" v-model="paramsProductos.search" @keyup.enter="changeCategoria" />
                <button class="btn btn-outline-secondary btn-buscar" type="button" v-on:click="changeCategoria"
                  title="Buscar">
                  <fa icon="search" />
                </button>
              </div>
            </div>
            <li class="nav-item">
              <router-link to="/" class="nav-link pb-0" v-slot="{ isActive, isExactActive }" aria-current="page">
                <p :class="
                  isActive && isExactActive ? 'text-secondary' : 'text-client'
                ">
                  <fa icon="home" class="bi d-block mx-auto mb-1" :class="[sizeIcon]" />
                  Inicio
                </p>
              </router-link>
            </li>

            <li v-if="issuserParam.ocultarVentanaContacto != 2" class="nav-item">
              <router-link to="/about" class="nav-link" v-slot="{ isActive, isExactActive }">
                <p :class="
                  isActive && isExactActive ? 'text-secondary' : 'text-client'
                ">
                  <fa icon="phone" class="bi d-block mx-auto mb-1" :class="[sizeIcon]" />
                  Contacto
                </p>
              </router-link>
            </li>
            <li class="nav-item d-md-block d-lg-block d-none">
              <router-link to="/cart" class="
                    nav-link
                    text-client
                    position-relative
                    border-start-0
                    rounded-0
                  " v-slot="{ isActive, isExactActive }">
                <p v-if="issuserParam.deshabilitarCarrito != 'S'" :class="
                  isActive && isExactActive ? 'text-secondary ' : 'text-client'
                ">
                  <fa icon="shopping-cart" class="bi d-block mx-auto mb-1" :class="[sizeIcon]" />
                  Carrito
                  <span class="
                        position-absolute
                        translate-middle
                        badge
                        rounded-pill
                        bg-client
                      " style="top: 0.7rem !important" v-if="cart.length">
                    {{ cart.length }}
                    <span class="visually-hidden">En el carrito</span>
                  </span>
                </p>
              </router-link>
            </li>
            <li class="nav-item d-none">
              <a class="nav-link text-client" href="javascript:void(0);">
                <fa icon="user-circle" class="bi d-block mx-auto mb-1" :class="[sizeIcon]" />
                Usuario
              </a>
            </li>
            <li class="nav-item d-md-none d-lg-none d-block">
              <a href="#offcanvasExample" class="nav-link text-client mt-1" id="offcanvasId" data-bs-toggle="offcanvas"
                role="button" aria-controls="offcanvasExample">
                <fa icon="search" class="d-block mx-auto" :class="[sizeIcon]" />
                Buscar
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="
            container-fluid
            justify-content-center
            bg-semi-black-transparent
            d-none d-sm-block
            px-3
          ">
        <Carousel class="catalogNavbar" :settings="settings" :breakpoints="breakpoints" :wrap-around="true"
          v-if="tieneGruposCategorias">
          <Slide class="nav-item categories" v-for="categoria in categorias" :key="categoria.codigoGrupo"
            style="height:min-content!important">
            <a class="nav-link dropdown-toggle text-white" href="javascript:void(0);" role="button" data-bs-toggle="modal"
              data-bs-target="#modalSubCategoria" @click="loadModalSubCategorias(categoria)">
              {{ categoria.nombreGrupo }}
            </a>
            <!--<div class="dropdown">
              <a class="nav-link dropdown-toggle text-white" role="button" id="dropdownMenuLink"
                data-bs-toggle="dropdown" aria-expanded="false" @click="loadModalSubCategorias(categoria)">
                {{ categoria.nombreGrupo }}
              </a>

              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink"  style="z-index: 9999!important;position:static!important; overflow-y: auto!important;">
                <li><a class="dropdown-item" href="#"  v-for="item in categoriaSelected.items" :key="item.codigo"
                  @click="searchGrupo(item.codigo, categoriaSelected.codigoGrupo)"><fa icon="arrow-right" /> {{ item.nombre }}</a></li>
              </ul>
            </div>-->
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>

        <Carousel class="nav catalogNavbar" :settings="settings" :breakpoints="breakpoints" :wrap-around="true" v-else>
          <Slide class="nav-item" v-for="categoria in categorias" :key="categoria.codigo">
            <a class="nav-link text-white" href="javascript:void(0);" v-on:click="searchCategorias(categoria.codigo)">{{
              categoria.nombre
            }}</a>
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
    </nav>
  </header>
  <div class="modal fade" id="modalSubCategoria" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    v-if="tieneGruposCategorias">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ categoriaSelected.nombreGrupo }}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            id="closeModalSubCategoria"></button>
        </div>
        <div class="modal-body">
          <div class="list-group">
            <button type="button" class="list-group-item list-group-item-action" aria-current="true"
              v-for="item in categoriaSelected.items" :key="item.codigo" @click="searchGrupo(item.codigo)">
              <fa icon="arrow-right" /> {{ item.nombre }}
            </button>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, computed, ref } from "vue";

import { Carousel, Navigation, Slide } from "vue3-carousel";

import { goTo } from "@/composable/functions";

import { useRoute } from "vue-router";
import router from "@/router";
export default {
  name: "navbar",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      fixed: "",
      drop: "",
      sizeIcon: "",
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
    };
  },
  setup() {
    const route = useRoute();
    const categorias = inject("categorias");
    const tieneGruposCategorias = inject("tieneGruposCategorias");
    const necesitacarousel = inject("necesitacarousel");
    const cargarProductos = inject("cargarProductos");
    const pagActive = inject("pagActive");
    const paramsProductos = inject("paramsProductos");
    const issuserParam = inject("issuserParam");
    const cart = inject("cart");
    const styleClass = inject("styleClass");
    const categoriaSelected = ref({});


    const breakpoints = computed(() => {
      console.log("issuserParam: " + JSON.stringify(issuserParam._value.cantitemscarouselGrande))
      return {
        500: {
          itemsToShow:
            categorias.value.length / 2 / 2 <= 3 ? 1 : 3,
          snapAlign: "center",
        },
        765: {
          itemsToShow:
            issuserParam._value.cantitemscarouselMedio != null ? issuserParam._value.cantitemscarouselGrande : 5,
          snapAlign: "center",
        },
        1200: {
          itemsToShow:
            issuserParam._value.cantitemscarouselMedio != null ? issuserParam._value.cantitemscarouselGrande : 5,
          snapAlign: "center",
        },
      };
    });

    const searchGrupo = (codigo) => {
      pagActive.value = 1;
      paramsProductos.value.start = pagActive.value;
      paramsProductos.value.categoria = codigo;
      paramsProductos.value.orderby = 1;
      paramsProductos.value.search = "";
      cargarProductos(paramsProductos.value, sendFiltros);
      document.getElementById("closeModalSubCategoria").click();
    };

    const searchCategorias = async (codigo) => {
      pagActive.value = 1;
      paramsProductos.value.start = pagActive.value;
      paramsProductos.value.categoria = codigo;
      paramsProductos.value.orderby = 1;
      paramsProductos.value.search = "";
      await cargarProductos(paramsProductos.value, () => {
        goTo("articlesDisplay", 100);
        if (route.path !== "/") {
          router.push({ name: "Home" });
        }
      });
    };

    const loadModalSubCategorias = (categoria) => {
      categoriaSelected.value = categoria;
      paramsProductos.value.grupo = categoriaSelected.value.codigoGrupo;
    };

    const changeCategoria = async () => {
      pagActive.value = 1;
      paramsProductos.value.start = pagActive.value;
      cargarProductos(paramsProductos.value, sendFiltros);
    }

    const sendFiltros = () => {
      if (tieneGruposCategorias && paramsProductos.value.grupo != -1) {
        router.push({
          name: "Catalog",
          params: { id: paramsProductos.value.grupo },
        });
      } else if (
        !tieneGruposCategorias &&
        paramsProductos.value.categoria != -1
      ) {
        router.push({
          name: "Catalog",
          params: { id: paramsProductos.value.categoria },
        });
      } else {
        router.push({ name: "Catalog" });
      }
      goTo("articlesDisplay", 100);
    };


    return {
      categorias,
      tieneGruposCategorias,
      necesitacarousel,
      paramsProductos,
      changeCategoria,
      searchGrupo,
      searchCategorias,
      issuserParam,
      cart,
      styleClass,
      loadModalSubCategorias,
      breakpoints,
      categoriaSelected,
    };
  },
  created() {
    this.myEventHandler();
    window.addEventListener("resize", this.myEventHandler);
    document.addEventListener("DOMContentLoaded", this.dropDownMultiLevel);
  },
  unmounted() {
    window.removeEventListener("resize", this.myEventHandler);
    document.addEventListener("DOMContentLoaded", this.dropDownMultiLevel);
  },
  mounted() { },
  methods: {
    myEventHandler() {

      this.fixed = "fixed-top";
      this.drop = "";
      this.sizeIcon = "fa-1x";

      //quitando el :class="[fixed]" de el tag <nav> se deja de usar este codigo (o poniendolo se pone de nuevo en caso de)
      //este codigo comentado pasaba la barra para abajo cuando la pantalla era de menos de x resolucion 

      // if (window.innerWidth < 575) {
      //   this.fixed = "fixed-bottom";
      //   this.drop = "dropup";
      //   this.sizeIcon = "";
      // } else {
      //   this.fixed = "fixed-top";
      //   this.drop = "";
      //   this.sizeIcon = "fa-1x";
      // }
    },
    dropDownMultiLevel() {
      document.querySelectorAll(".dropdown-menu").forEach(function (element) {
        element.addEventListener("click", function (e) {
          e.stopPropagation();
        });
      });
      // make it as accordion for smaller screens
      if (window.innerWidth < 992) {
        // close all inner dropdowns when parent is closed
        document
          .querySelectorAll(".navbar .dropdown")
          .forEach(function (everydropdown) {
            everydropdown.addEventListener("hidden.bs.dropdown", function () {
              // after dropdown is hidden, then find all submenus
              this.querySelectorAll(".submenu").forEach(function (
                everysubmenu
              ) {
                // hide every submenu as well
                everysubmenu.style.display = "none";
              });
            });
          });
        document
          .querySelectorAll(".dropdown-menu a")
          .forEach(function (element) {
            element.addEventListener("click", function (e) {
              let nextEl = this.nextElementSibling;
              if (nextEl && nextEl.classList.contains("submenu")) {
                // prevent opening link if link needs to open dropdown
                e.preventDefault();
                if (nextEl.style.display == "block") {
                  nextEl.style.display = "none";
                } else {
                  nextEl.style.display = "block";
                }
              }
            });
          });
      }
    },
  },
};
</script>

<style lang="scss">
/*----------------------------------------*/
/* Header Menu Multi-Level Details
/*----------------------------------------*/

/* Navbar container */
.navbar-details {
  overflow: hidden;
  /*background-color: #1a1a1a;*/
}

/* Links inside the navbar */
.navbar-details a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* The dropdown container */
.dropdown-details {
  float: left;
  margin-right: 5px;
}

@media(max-width: 576px) {
  #contenedornumeroscabezal {
    display: none;
  }
}


/* Dropdown button */
.dropdown-details .dropbtn-details {
  font-size: 16px;
  outline: none;
  padding: 14px 16px;
  background-color: #fff;
  color: #1a1a1a;
  border: solid #1a1a1a 0.5px;
  font: inherit;
  /* Important for vertical align on mobile phones */
  margin: 0;
  /* Important for vertical align on mobile phones */
}

.dropdown-details:hover .dropbtn-details {
  background: #444;
  color: #fff;
  border: solid #1a1a1a 0.5px;
}

.navbar-details a:hover {
  background-color: #fff;
  color: #1a1a1a;
  border: solid #1a1a1a 0.5px;
}

.navbar-details .menuCategoriaGrupo a {
  margin: 10px;
  text-align: center;
}

.navbar-details .menuCategoriaGrupo a {
  font-size: 14px;
  font-weight: 600;
  background: #444;
  color: #fff;
  padding: 15px 30px;
  display: inline-block;
  border: 1px solid #1a1a1a;
  text-transform: uppercase;
}

.navbar-details .menuCategoriaGrupo a:hover {
  background: transparent;
  color: #1a1a1a;
}

/* Dropdown content (hidden by default) */
.dropdown-content-details {
  left: 30px;
  display: none;
  /*position: absolute;*/
  position: fixed;
  background-color: #f9f9f9;
  width: 90%;
  margin: 0 auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  min-height: 300px;
}

/* Mega Menu header-img, if needed */
.dropdown-content-details .header-img {
  background: red;
  padding: 16px;
  color: white;
}

.categories {
  width: 13em !important;
}

/* Show the dropdown menu on hover */
.dropdown-details:hover .dropdown-content-details {
  display: block;
}

// .row:after {
//     content: "";
//     display: table;
//     clear: both;
// }
// @media screen and (max-width: 600px) {
//     .column {
//         width: 100%;
//         height: auto;
//     }
// }
.catalogNavbar .carousel__prev,
.catalogNavbar .carousel__next {
  box-sizing: content-box;
  background-color: transparent;
  color: v-bind(styleClass);
  // background-color: v-bind(styleClass);
}

.dark-mode .catalogNavbar .carousel__prev,
.dark-mode .catalogNavbar .carousel__next {
  box-sizing: content-box;
  color: v-bind(styleClass);
  // background-color: v-bind(styleClass);
}</style>