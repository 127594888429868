<template>
  <div class="card text-center h-100" :class="classes">
    <div class="position-relative overflow-hidden shadow-lg p-2 ">
      <a href="javascript:void(0)">
        <img
          :src="[
            producto.imagen.includes('no-img')
              ? PUBLICPATH+'img/new-products/no-img.jpg'
              : producto.ruta + producto.imagen,
          ]"
          class="card-img-top img-escritorio img-telefono"
          alt="Imagen"
          :style="[
            `object-fit: ${
              getStrategyImage(issuserParam.estrategiaImagen)
                ? getStrategyImage(issuserParam.estrategiaImagen)
                : getStrategyImage('2')
            }`,
            styleImage,
          ]"
          @click="viewDetail(producto)"
        />
        <img
          v-if="producto.stock <= 0"
          class="position-absolute top-0 start-0"
          :src="[PUBLICPATH+'img/new-products/sinStock.png']"
          alt="Sin Stock"
          @click="viewDetail(producto)"
        />
        <h5
          class="position-absolute top-0 end-0 card-title"
          v-on:click="toggleFav(producto.codigo)"
        >
          <span
            class="badge rounded-pill bg-light"
            :class="[
              favList.includes(producto.codigo)
                ? 'text-warning'
                : 'text-secondary',
            ]"
            ><fa icon="star"
          /></span>
        </h5>
        <h5
          class="position-absolute bottom-0 end-0 card-title"
          v-if="parseFloat(producto.PctDescuento)"
        >
          <span class="badge rounded-pill bg-danger"
            ><fa icon="tag" /> -{{ producto.PctDescuento }}%</span
          >
        </h5>
      </a>
    </div>
    <div v-if="issuserParam.deshabilitarCarrito != 'S'" class="card-body">
      <p v-if="issuserParam.mostrarcodigoproductocatalogo == 'S' && issuserParam.tipoMarketTienda != 1 " class="card-title lh-1">
        <a href="javascript:void(0)" class="text-secondary text-decoration-none" style="
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              " @click="viewDetail(producto)">{{ producto.nombreProducto+"("+producto.codigo+")" }}</a>
      </p>
      <p v-if="issuserParam.mostrarcodigoproductocatalogo == 'S' && issuserParam.tipoMarketTienda == 1" class="card-title lh-1">
        <a href="javascript:void(0)" class="text-secondary text-decoration-none" style="
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              " @click="viewDetail(producto)">{{ producto.nombreProducto+"("+producto.idFamilia+")" }}</a>
      </p>
      <p v-if="issuserParam.mostrarcodigoproductocatalogo != 'S'" class="card-title lh-1">
        <a href="javascript:void(0)" class="text-secondary text-decoration-none" style="-webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              " @click="viewDetail(producto)">{{ producto.nombreProducto}}</a>
      </p>
      <h5 class="card-subtitle text-decoration-line-through fw-bold" v-if="parseFloat(producto.PctDescuento) && issuserParam.deshabilitarPrecios !='S'">
        {{ formatMoney(issuserParam.monedalp) }}
        {{ parseFloat(producto.precioUnitario).toFixed(decimal) }}
      </h5>
      <h5 class="card-subtitle fw-bold h5_card_precio precio-carrito-escritorio precio-carrito-telefono" v-if="issuserParam.deshabilitarPrecios !='S'">
        {{ formatMoney(issuserParam.monedalp) }}
        {{
        formatPrice(
        parseFloat(
        calcSalePrice(
        producto.precioUnitario,
        producto.PctDescuento,
        decimal
        )
        ) +
        adjust10th(
        issuserParam.configRedondeoDecimas,
        calcSalePrice(
        producto.precioUnitario,
        producto.PctDescuento,
        decimal
        )
        ),
        decimal
        )
        }}
        <button
          v-if="(issuserParam.tipoMarketTienda == 1 || issuserParam.tipoMarketTienda == 2)"
          class="btn btn-client btn-carrito-escritorio btn-carrito-telefono"  @click="viewDetail(producto)">
          <fa class="icon-carrito-telefono" icon="cart-plus"  />
          <span></span>
        </button>
        <button
          v-else-if="!(issuserParam.tipoMarketTienda == 1 || issuserParam.tipoMarketTienda == 2)"
          class="btn btn-client btn-carrito-escritorio btn-carrito-telefono" style="margin-left: 20%;"   @click="saveProducto(producto)">
          <fa class="icon-carrito-telefono" icon="cart-plus" />
          <span></span>
        </button>
      </h5>
    </div>
    <div v-if="issuserParam.deshabilitarCarrito == 'S'" class="card-body">
      <p class="card-title lh-1">
        <a href="javascript:void(0)" class="text-secondary text-decoration-none" style="-webkit-line-clamp: 2; overflow: hidden; text-overflow: ellipsis; display: -webkit-box;-webkit-box-orient: vertical;" @click="viewDetail(producto)">{{ producto.nombreProducto }}</a>
      </p>
      <h5 class="card-subtitle text-decoration-line-through fw-bold" v-if="parseFloat(producto.PctDescuento)">
        {{ formatMoney(issuserParam.monedalp) }}
        {{ parseFloat(producto.precioUnitario).toFixed(decimal) }}
      </h5>
      <h5 class="card-subtitle fw-bold h5_card_precio" v-if="issuserParam.deshabilitarPrecios !='S'">
        {{ formatMoney(issuserParam.monedalp) }}
        {{
        formatPrice(
        parseFloat(
        calcSalePrice(
        producto.precioUnitario,
        producto.PctDescuento,
        decimal
        )
        ) +
        adjust10th(
        issuserParam.configRedondeoDecimas,
        calcSalePrice(
        producto.precioUnitario,
        producto.PctDescuento,
        decimal
        )
        ),
        decimal
        )
        }}
      </h5>
    </div>
    <div v-if="issuserParam.deshabilitarCarrito != 'S'" class="card-footer bg-transparent border-light">
      <div v-if="issuserParam.tipoMarketTienda != 1 && issuserParam.tipoMarketTienda != 2" class="row">
        <div class="col-12 col-sm-12">
          <div class="input-group cantbuttons justify-content-center">
            <button
              class="btn btn-outline-secondary minusbutton rounded-3"
              type="button"
              v-on:click="restarCantidad(producto)"
            >
              <fa icon="minus" />
            </button>
            <input
              class="form-control inputcount text-center border-0"
              type="number"
              v-model="producto.cantidad"
              :run="
                producto.cantidad ? producto.cantidad : (producto.cantidad = 1)
              "
              style="width: 25%; flex: none; margin: 1px; padding: inherit"
            />
            <button
              class="btn btn-outline-secondary plusbutton rounded-3"
              type="button"
              v-on:click="sumarCantidad(producto)"
            >
              <fa icon="plus" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import {
  adjust10th,
  getStrategyImage,
  getResponsiveCard,
  formatPrice
} from "@/composable/functions";
import router from "@/router";
import Swal from "sweetalert2";

export default {
  name: "article-product",
  props: {
    product: Object,
    classes: String,
    styleImage: String,
  },
  data: function () {
    return {
      producto: this.product,
    };
  },
  setup() {
    const decimal = inject("decimal");
    const issuserParam = inject("issuserParam");
    const saveProductoApp = inject("saveProducto");
    const styleClass = inject("styleClass");
    const favList = inject("favList");
    const PUBLICPATH = inject("PUBLICPATH");

    
    const sumarCantidad = (producto) => {
      return producto.cantidad++;
    };
    const restarCantidad = (producto) => {
      return producto.cantidad ? producto.cantidad-- : 1;
    };
    const toggleFav = (codigo) => {
      if (favList.value.includes(codigo)) {
        favList.value = favList.value.filter((item) => item !== codigo);
      } else {
        favList.value.push(codigo);
      }
      localStorage.setItem("fav", JSON.stringify(favList.value));
    };
    
    const viewDetail = (producto) => {
      router.push({ name: "DetailArticle", params: { id: producto.codigo } });
    };

    const saveProducto = (producto) => {
      if (producto.tieneTalleColor) {
        viewDetail(producto);
        return producto;
      }
      producto.sinStock = false;
      producto.carritoSuperiorStock = false;
      saveProductoApp(producto);
      if (producto.sinStock || producto.carritoSuperiorStock) {
        return producto;
      }
      Swal.fire({
        icon: "success",
        title: "Producto agregado al carrito!",
        html: "¿Desea ir al carrito?",
        showDenyButton: true,
        confirmButtonText: "Ir al carrito",
        denyButtonText: `Seguir comprando`,
      }).then((result) => {
        if (result.isConfirmed) {
          router.push({ name: "Cart" });
        }
      });
    };

    return {
      favList,
      sumarCantidad,
      restarCantidad,
      toggleFav,
      viewDetail,
      issuserParam,
      adjust10th,
      decimal,
      saveProducto,
      styleClass,
      getStrategyImage,
      getResponsiveCard,
      PUBLICPATH,
      formatPrice
    };
  },
};
</script>

<style>


@media(min-width: 576px) {
  .btn-carrito-escritorio {
    margin-left: 20%;
   
  }
  .precio-carrito-escritorio {
    margin-left: 25%;
  }
}


@media(max-width: 576px) {
  .precio-carrito-telefono {
    font-size: 15px;
  }
  .btn-carrito-telefono {
    font-size: 80% !important;
  }
  .icon-carrito-telefono {
    font-size: 100% !important;
  }
}


</style>