<template>
  <div class="row px-5" v-if="colCarrito.length">
    <div class="row mb-5 mb-sm-0 mt-0 mt-sm-5">
      <div class="col text-center">
        <h3>Pago correcto con MercadoPago</h3>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Producto</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(article, index) in colCarrito" :key="index">
                <th scope="row">
                  {{article.nombreProducto}} <strong class="product-quantity"> x {{article.cantidad}}</strong>
                </th>
                <th scope="row">
                  <span class="amount">$ {{formatPrice(article.precioVenta,decimal)}}</span>
                </th>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Total</th>
                <th>{{formatMoney(issuserParam.monedalp)}} {{formatPrice(computedTotal,decimal)}}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <h5>Información Cliente:</h5>
        <p>Nombre: {{respuestaMp.nombrePedido}}</p>
        <p>Dirección: {{respuestaMp.direccionPedido}}</p>
        <p>Ciudad: {{respuestaMp.ciudadPedido}}</p>
        <p>Email: {{respuestaMp.emailPedido}}</p>
        <p>Comentario: {{respuestaMp.comentarioPedido}}</p>
      </div>
      <div class="col-12 col-md-4">
        <h5>Información Mercado Pago:</h5>
        <p>Estado: Aprobado</p>
        <p>Tipo Pago: {{objVuelta.paymentType}}</p>
        <p>Id Orden Mercado Pago: {{objVuelta.merchantOrderId}}</p>
        <p>Token Transacción: {{objVuelta.tokenMP}}</p>
      </div>
      <div class="col-12 col-md-4">
        <h5>Información</h5>
        <p>Tipo Comprobante: {{objVuelta.tipoComprobante}} {{tipoComprobantes(objVuelta.tipoComprobante)}}</p>
        <p>Serie: {{objVuelta.serie}}</p>
        <p>Numero: {{objVuelta.numero}}</p>
        <p>
          Link:
          <a :href="respuestaMp.linkFactura" class="link_FE" target="_blank"
            >Ver Factura</a
          >
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button
          class="btn btn-outline-primary"
          @click="router.push({ name: 'Home' })"
        >
          <fa icon="cart-arrow-down" /> Continuar Comprando
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { formatMoney, tipoComprobantes } from "@/composable/functions";
import { inject, ref, watch, computed } from "vue";
import router from "@/router";
export default {
  setup() {
    const decimal = inject("decimal");
    const issuserParam = inject("issuserParam");
    const postApiGeneric = inject("postApiGeneric");
    const respuestaMp = ref({});
    const objVuelta = ref ({});
    const colCarrito = ref ([]);
    const route = useRoute();

    const computedTotal = computed(()=>{
        return parseFloat(colCarrito.value.reduce((acc, curr) =>{
          return acc+parseFloat(curr.subTotal);
      }, 0));
    });

    if (issuserParam.value.linkFE) {
      instanceMp();
    }
    watch(
      () => issuserParam.value,
      (newParams) => {
        if (!newParams) return;
        instanceMp();
      }
    );

    const instanceMp = () => {
      const collection_status = route.query.collection_status;
      const payment_type = route.query.payment_type;
      const merchant_order_id = route.query.merchant_order_id;
      const preference_id = route.query.preference_id;
      const external_reference = route.query.external_reference;

      if (!external_reference) {
          router.push({name:'Home'});
        return;
      }

      const datas = external_reference.split("-");
      objVuelta.value = {
        idEmisor: datas[0],
        codSucursal: datas[1],
        tipoComprobante: datas[2],
        serie: datas[3],
        numero: datas[4],
        idCarrito: datas[5],
        threadID: datas[6],
        moneda: datas[7],
        monedaFormat: formatMoney(datas[7]),
        usuario: datas[8],
        tokenMP: preference_id,
        merchantOrderId: merchant_order_id,
        paymentType: payment_type,
        estado: collection_status,
        linkFactura:
          issuserParam.value.linkFE +
          "xIdEmisor=" +
          datas[0] +
          "&xCodSucursal=" +
          datas[1] +
          "&xTipoComprobante=" +
          datas[2] +
          "&xSerie=" +
          datas[3] +
          "&xNumero=" +
          datas[4] +
          "&xRedirect=true",
      };
      postApiGeneric("mercadoPago/regreso", objVuelta, (retorno) => {
        respuestaMp.value = retorno.retorno;
        colCarrito.value = retorno.retorno.colCarrito;
      });
    };

    return { respuestaMp, router, objVuelta, tipoComprobantes, colCarrito, issuserParam, computedTotal, decimal };
  },
};
</script>

<style>
</style>