<template>
<loading-data v-if="loadingData" msg="Cargando Catálogo" classes="mb-5 mb-sm-0 mt-0"/>
<div class="container px-5 mt-5 padding pt-md-5 pt-lg-5 pt-3" v-else>
   <div class="row align-items-start text-left"><BackButton class="align-items-start"/></div> 
    <div class="row">
      <div class="col justify-content-around">
        <Articles />
      </div>
   
  </div>
</div>
</template>

<script>
import { useRoute } from "vue-router";
import {watch, inject, ref, onMounted} from 'vue';
import Articles from "@/components/Articles.vue";
import LoadingData from "@/components/LoadingData.vue";
import router from "@/router";
import BackButton from "@/components/BackButton.vue";
export default {
  components: { Articles,LoadingData, BackButton},
  setup() {
    const route = useRoute();
    const tieneGruposCategorias = inject("tieneGruposCategorias");
    const paramsProductos = inject("paramsProductos");
    const cargarProductos = inject("cargarProductos");
    const categorias = inject("categorias");
    const pagActive = inject("pagActive");
    const nombre = ref("Catálogo ");
    let loadingData = ref(true);

    const instanceCatalogo = () => {
      if (tieneGruposCategorias && route.params.id) {
        
        const categoria = categorias.value.find(categoria=>{
          return categoria.codigoGrupo == route.params.id;
        });     
        if (!categoria || !categoria.nombreGrupo){
          router.push({name: "Home"});
          return;
        }
        // nombre.value = "Catálogo: "+categoria.nombreGrupo;
        paramsProductos.value.grupo = route.params.id;
        //paramsProductos.value.categoria = -1;
        
      }else if (tieneGruposCategorias && !route.params.id){
        console.log("jiji")
      }else {
        const categoria = categorias.value.find(categoria=>categoria.codigo == route.params.id);
        if (!categoria || !categoria.nombre){
          router.push({name: "Home"});
          return;
        }
        // nombre.value = "Catálogo: "+categoria.nombre;
        paramsProductos.value.categoria = route.params.id;
      }
      pagActive.value = 1;
      paramsProductos.value.start = pagActive.value;
      delete paramsProductos.value.productos;
      cargarProductos(paramsProductos.value,()=>{loadingData.value=false;});
    };

    if (categorias.value.length) {
      instanceCatalogo();
    }

    watch(
      () => categorias.value,
      (newParams) => {
        if (!newParams) return;
        instanceCatalogo();
      }
    );

    onMounted(() => {
      window.scrollTo(0, 0);
    });

    return {nombre,loadingData}
  },
};
</script>

<style>
</style>