<template>
  <div class="container padding cont h-100 mt-2">
    <div class="row text-center mb-5 mb-sm-0 mt-0 mt-sm-5">
    </div>
    <div class="row" v-if="brandLogo.rutaLogo">
      <div class="col text-center">
        <img class="img-fluid" :src="[brandLogo.ruta + brandLogo.rutaLogo]" />
      </div>
    </div>
    <div class="row mt-5 h-100 pt-5 pb-5 mb-1" id="containerproductodetalle">
      <div class="row align-items-start text-left pb-1">
        <BackButton class="align-items-start" />
      </div>
      <div class="card border body-responsive">
        <div class="row">
          <div class="col-sm-12 col-md-6 text-center align-self-center mt-3 mb-3">
            <div id="carouselDetailArticle" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-indicators">
                <button type="button" class="item" data-bs-target="#carouselDetailArticle"
                  v-for="(item, index) in urlsCarousel" :key="item.codigo" :id="item.codigo" :data-bs-slide-to="index"
                  :class="index === 0 ? 'active' : ''" :aria-current="index === 0 ? 'true' : 'false'"
                  :aria-label="item.codigo"></button>
              </div>
              <div class="carousel-inner carousel-detail">
                <div class="carousel-item item" data-bs-interval="4000" :class="index === 0 ? 'active' : ''"
                  v-for="(item, index) in urlsCarousel" :id="'img_' + item.codigo" :key="item.codigo"
                  style="min-width: 100%;">
                  <img :src="item.ruta + item.imagen"
                    class="d-block h-100 mx-auto mw-100 imgDetail mh-100 mx-auto my-auto py-auto" :alt="item.imagen" />
                </div>
              </div>
              <button class="carousel-control-prev btn-carousel" type="button" data-bs-target="#carouselDetailArticle"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon " aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next btn-carousel" type="button" data-bs-target="#carouselDetailArticle"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="card-body">
              <h2 id="nombreProductoDetail" class="card-title">{{ producto.nombreBase ? producto.nombreBase :
                producto.nombreProducto }}</h2>
              <h3 class="card-subtitle margenCero text-muted" id="precioProducto" v-if="producto.precioUnitario && issuserParam.deshabilitarPrecios !='S'" >
                {{ formatMoney(issuserParam.monedalp) }}
                {{ producto.precioVenta }}
                <s v-if="parseFloat(producto.pctDescuento)">{{ formatMoney(issuserParam.monedalp) }}
                  {{ parseFloat(producto.precioUnitario).toFixed(decimal) }}</s>
              </h3>
              <div id="contenedorbotonescolorTEL">
                <a v-for="talle in tallesProductos" :key="talle.idTalle" class="btnTalle mt-2 btn me-1"
                  :class="[talle.clase ? talle.clase : 'btn-secondary']" v-bind:id="'talle_' + talle.ordinal"
                  @click="loadTalleColores(talle, talle.idTalle)">{{ talle.nombreTalle }}</a>
                <div id="li-listaColores" class="row mt-1 mb-1">
                  <div class="col-1 d-grid gap-2" v-for="color in tallesColores.colColores" :key="color.idColor">
                    <button class="btnColor mx-sm-3 btnColor-Telefono btn btn-lg" :style="[
                      `background-color:${color.codigoColor};`,
                      'border: 1px solid rgb(141 137 125);height:30px;',
                    ]" v-bind:id="'color_' + color.ordinal" :class="[color.class ? color.class : '']"
                      @click="saveColor(color)"></button>
                  </div>
                </div>
              </div>
              <p v-if="producto.codigo" id="codigoProducto" class="card-text mt-1 textoProducto">Código Producto: {{
                producto.codigo
              }}
              </p>
              <p v-if="producto.descripcion || producto.descripcionBse" id="descripcionProducto"
                class="card-text textoProducto">Descripción: {{
                  producto.descripcionBase ? producto.descripcionBase : producto.descripcion
                }}</p>
              <p id="stockProducto" class="card-text textoProducto"
                v-if="producto.stock && issuserParam.ocultarStockProductos != 2">
                Disponibles: {{ producto.stock }}</p>
              <p v-if="producto.desAdic" id="desAdicProducto" class="card-text textoProducto">{{
                producto.desAdic
              }}</p>
              <div id="contenedorbotonescolorPC">
                <a v-for="talle in tallesProductos" :key="talle.idTalle" class="btnTalle mt-3 btn me-1"
                  :class="[talle.clase ? talle.clase : 'btn-secondary']" v-bind:id="'talle_' + talle.ordinal"
                  @click="loadTalleColores(talle, talle.idTalle)">{{ talle.nombreTalle }}</a>
                <div id="li-listaColores" class="row mt-3">
                  <div class="col-1 d-grid gap-2" v-for="color in tallesColores.colColores" :key="color.idColor">
                    <button class="btnColor btnColor-Telefono btn-lg" :style="[
                      `background-color:${color.codigoColor};`,
                      'border: 1px solid rgb(141 137 125);height:30px;',
                    ]" v-bind:id="'color_' + color.ordinal"
                      :class="[colorSelected.idColor === color.idColor ? colorSelected.class : '']"
                      @click="saveColor(color)" ref="botoncito"></button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="issuserParam.deshabilitarCarrito != 'S'" class="card-footer bg-transparent border-light">
              <div class="row">
                <div class="col-sm-4 px-sm-0 px-md-0 px-lg-3 px-0">
                  <div class="input-group cantbuttons">
                    <button class="btn btn-outline-secondary minusbutton" style="z-index: 0" type="button"
                      @click="restarCantidad">
                      <fa icon="minus" />
                    </button>
                    <input class="form-control inputcount text-center" type="number" v-model="cantidad"
                      id="cantidadDetalle" />
                    <button class="btn btn-outline-secondary plusbutton" style="z-index: 0" type="button"
                      @click="sumarCantidad">
                      <fa icon="plus" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mt-3"
                v-if="(issuserParam.tipoMarketTienda == null || issuserParam.tipoMarketTienda == 0) && issuserParam.deshabilitarCarrito != 'S'">
                <div class="col">
                  <button class="btn btn-client" @click="saveProducto()">
                    <fa icon="cart-plus" />
                    <span id="BtnAgregarCarritoDetail" v-html="producto.stock <= 0 ? 'Sin Stock' : 'Agregar'"></span>
                  </button>
                </div>
              </div>
              <div class="row mt-3 itemsCenter"
                v-if="(issuserParam.tipoMarketTienda != null && issuserParam.tipoMarketTienda != 0) && issuserParam.deshabilitarCarrito != 'S'">
                <div class="col">
                  <button class="btn btn-client ml-3" @click="saveProducto()">
                    <fa icon="cart-plus" />
                    <span id="BtnAgregarCarritoDetail">Agregar</span>
                  </button>
                </div>
              </div>
              <div hidden class="row mt-1 text-center">
                <div class="row">
                  <div class="col">
                    <a id="sharefacebook" class="btn btn-primary mx-1" title="Compartir en Facebook" target="_blank"
                      href="#">
                      <fa :icon="['fab', 'facebook']" />
                    </a>
                    <a id="sharewpp" class="btn btn-success mx-1" target="_blank" title="Compartir en WhatsApp" href="#">
                      <fa :icon="['fab', 'whatsapp']" />
                    </a>
                    <a id="sharetw" class="btn btn-primary mx-1" target="_blank" title="Compartir en Twitter" href="#">
                      <fa :icon="['fab', 'twitter']" />
                    </a>
                    <a id="sharemail" class="btn btn-info text-white mx-1" target="_blank" title="Enviar por mail"
                      href="#">
                      <fa icon="envelope" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row pb-2 pb-md-0 pb-lg-0">
      <vue-easy-lightbox :visible="visible" :imgs="imgs" :index="index" @hide="handleHideImg"></vue-easy-lightbox>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { inject, ref, watch, onMounted } from "vue";
import router from "@/router";
import { calcSalePrice, adjust10th } from "@/composable/functions";
import Swal from "sweetalert2";
import BackButton from "@/components/BackButton.vue";

export default {
  components: {
    BackButton
  },
  setup() {
    const decimal = inject("decimal");
    const route = useRoute();
    const producto = ref({});
    const getApiGeneric = inject("getApiGeneric");
    const issuserParam = inject("issuserParam");
    const saveProductoApp = inject("saveProducto");

    let imgs = ref([]);

    let visible = ref(false);
    let brandLogo = {};
    const urlsCarousel = ref([]);
    let cantidad = ref(1);
    const tallesProductos = ref([]);
    const tallesColores = ref({ colColores: [], clase: "btn-default" });
    const colorSelected = ref({});
    const talleSelected = ref({});
    const currentIndex = ref(0);
    const index = ref(0);

    const sumarCantidad = () => {
      return (cantidad.value = parseFloat(cantidad.value) + 1);
    };

    const restarCantidad = () => {
      return (cantidad.value =
        cantidad.value - 1 ? parseFloat(cantidad.value) - 1 : 1);
    };

    const showImg = (i) => {
      index.value = i;
      visible.value = true;
    };
    const handleHideImg = () => {
      visible.value = false;
    };

    const instanceProducto = () => {
      let url = `productos/detalle`;
      let urlapi = `productos`;

      //si el emisor tiene pluguin tienda primero hay que obtener los talles y colores
      if (issuserParam.value.tipoMarketTienda != null && issuserParam.value.tipoMarketTienda != 0) {
        urlapi = `productosTienda`;
        getApiGeneric(
          urlapi + `/tallesProductos`,
          { producto: route.params.id },
          (talles) => {
            if (talles.cod_respuesta === "00") {
              tallesProductos.value = talles.retorno;
              //Luego de obtener los talles en esta primera llamada seleccionaremos por defecto el primero del arreglo
              loadTalleColores(tallesProductos.value[0], tallesProductos.value[0].idTalle);
              //Luego de seleccionar el primner talle, seleccionamos el primer color
              saveColor(tallesColores.value.colColores[0]);
            }
          }
        );
      } else {
        //si el emisor no tiene pluguin tienda
        let param = { producto: route.params.id, lp: issuserParam.value.idLP };
        //si tiene algun deposito asignado o todos y no tiene pluguin tienda
        if (issuserParam.value.idDeposito >= 0 && !(issuserParam.value.tipoMarketTienda != null && issuserParam.value.tipoMarketTienda != 0)) {
          url = `productosStock/detalle`;
          param.deposito = issuserParam.value.idDeposito;
        }

        //Obtenemos el producto
        getApiGeneric(url, param, (prod) => {
          //lo instanciamos para que el vue lo renderize
          producto.value = prod.retorno;
          actualizarPrecioVenta();
          setearImagenesCarousel();

          //si tiene talle y color obtenemos los taller y colores
          if (producto.value.tieneTalleColor) {
            getApiGeneric(
              urlapi + `/tallesProductos`,
              { producto: route.params.id },
              (talles) => {
                if (talles.cod_respuesta === "00") {
                  tallesProductos.value = talles.retorno;
                  loadTalleColores(tallesProductos.value[0], tallesProductos.value[0].idTalle);
                  saveColor(tallesColores.value.colColores[0]);
                }
              }
            );
          }
        });
      }
      //obtenemos la marca
      getApiGeneric(
        `productos/marca`,
        { producto: route.params.id },
        (prod) => {
          brandLogo = { ruta: prod.ruta, rutaLogo: prod.rutaLogo };
        }
      );
    };

    //esta funcion calcula el precio de venta del producto en base al precio unitario, el porcentaje de descuento y los decimales configurados
    const actualizarPrecioVenta = () => {
      producto.value.precioVenta = (
        parseFloat(
          calcSalePrice(
            producto.value.precioUnitario,
            producto.value.pctDescuento,
            decimal
          )
        ) +
        adjust10th(
          issuserParam.value.configRedondeoDecimas,
          calcSalePrice(
            producto.value.precioUnitario,
            producto.value.pctDescuento,
            decimal
          ),
          decimal
        )
      ).toFixed(decimal);
    }

    //Esta funcion coloca las imagenes en el carousel, si no hay imagenes coloca "/no-img.jpg"
    const setearImagenesCarousel = () => {

      urlsCarousel.value = producto.value.Imagenes && producto.value.Imagenes.retorno.length ? producto.value.Imagenes.retorno : [{ ruta: "/img/new-products", imagen: "/no-img.jpg" }];
      imgs.value.push(
        !producto.value.imagen || producto.value.imagen.includes("no-img")
          ? "/img/new-products/no-img.jpg"
          : producto.value.ruta + producto.value.imagen
      );
    }

    //Esta funcion me trae los colores del talle seleccionado
    //Tambien le da la clase active cuando el boton es clickeado
    const loadTalleColores = (talle, idTalle) => {

      tallesColores.value.clase = "";
      tallesColores.value = talle;
      tallesColores.value.clase = "btn-dark";
      talleSelected.value = idTalle;

      /// si ya hay un color seleccionado (esto no sucede la primera vez que se llama a esta funcion) y existe un producto con este talle y color
      if (Object.keys(colorSelected.value).length > 0 && talleTieneColor(colorSelected.value)) {

        //llamamos al producto con el talle y el color seleccionado
        saveColor(colorSelected.value);

        //si hay un color seleccionado pero no existe un producto con este talle y color
      } else if (Object.keys(colorSelected.value).length > 0) {

        //le avismos al usuario
        Swal.fire("Por el momento el producto no se encuentra en talle " + tallesColores.value.nombreTalle + " y color " + colorSelected.value.nombreColor.toLowerCase(), "", "warning");

        //llamamos al producto de este talle con el primer color que exista
        saveColor();
      }
    };


    //esta funcion invoca el detalle del producto con el talle y color seleccionado(si no hay un color llama al primer color del talle seleccionado)
    // si se le pasa un color como parametro la funcion ademas le da la clase active al boton con este color
    const saveColor = (color) => {
      let idColor
      if (color) {
        colorSelected.value.class = "";
        colorSelected.value = color;
        colorSelected.value.class = "border-2 borderSelected";
        idColor = colorSelected.value.idColor;
      } else {
        idColor = tallesColores.value.colColores[0].idColor
      }
      getDetalleProductoTienda(idColor);
    };

    //Esta funcion es para averiguar si existe un color en un talle
    const talleTieneColor = (color) => {
      const encontrado = tallesColores.value.colColores.find(talleColor => talleColor.idColor === color.idColor);
      return !!encontrado; // convierte el objeto encontrado en true o false
    }

    const getDetalleProductoTienda = (idColor) => {
      if (issuserParam.value.tipoMarketTienda != null && issuserParam.value.tipoMarketTienda != 0) {
        let urlapi = `productosTienda`;
        // Mostrar imagen:
        getApiGeneric(
          urlapi + `/actualizarDetallesProducto`,
          { producto: route.params.id, idColor: idColor, idTalle: talleSelected.value, idDeposito: issuserParam.value.idDeposito, idLP: issuserParam.value.idLP },
          (data) => {
            if (data.cod_respuesta === "00") {
              //asigno valores de variables
              //let moneda;
              //stockProducto.value = data.retorno.Stock;
              /* idProducto.value = data.retorno.idProducto ? data.retorno.idProducto : data.retorno.codigo;
              nombreProducto.value = data.retorno.nombreProducto; */
              producto.value = data.retorno;
              actualizarPrecioVenta();
              setearImagenesCarousel();
            }
          }
        );
      }
    }

    if (issuserParam.value.idDeposito) {
      instanceProducto();
    }


    watch(() => urlsCarousel.value,
      //perdon x hacer esto me duele un monton pero no encontre otra solucion, luego lo voy a hacer bien :(
      (newParams) => {
        if (!newParams) return;
        if (newParams.length > 1) {
          let elementos = document.querySelectorAll('.active');
          for (let i = 0; i < elementos.length; i++) {
            elementos[i].classList.remove('active');
          }
        }
        
        let item = document.getElementById(urlsCarousel.value[0].codigo);
        let img = document.getElementById('img_' + urlsCarousel.value[0].codigo);
        if (item != null && img != null) {
          item.classList.add('active');
          img.classList.add('active');
        }

      }
    );


    const saveProducto = () => {
      if (producto.value.tieneTalleColor || (issuserParam.value.tipoMarketTienda != null && issuserParam.value.tipoMarketTienda != 0)) {
        if (!colorSelected.value.idColor || !tallesColores.value.idTalle) {
          Swal.fire('Debe seleccionar un talle y color.', '', 'warning');
          return;
        }
        producto.value.nombreTalle = tallesColores.value.nombreTalle;
        producto.value.nombreColor = colorSelected.value.nombreColor;

      }

      producto.value.cantidad = cantidad.value;
      saveProductoApp(producto.value, () => {

        router.push({ name: "Cart" });
      });
    };

    watch(
      () => issuserParam.value,
      (newParams) => {
        if (!newParams) return;
        instanceProducto();
      }
    );


    onMounted(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });

    return {
      producto,
      issuserParam,
      showImg,
      handleHideImg,
      index,
      visible,
      imgs,
      brandLogo,
      cantidad,
      sumarCantidad,
      restarCantidad,
      saveProducto,
      decimal,
      tallesProductos,
      loadTalleColores,
      tallesColores,
      saveColor,
      urlsCarousel,
      colorSelected,
      currentIndex
    };
  },
};
</script>

<style>
.btn-carousel {
  background: rgb(0, 0, 0) !important;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.3883928571428571) 0%, rgba(255, 255, 255, 0) 11%) !important;
}

@media(max-width: 576px) {

  .itemsCenter {
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
  }

  .carousel-detail img {
    max-height: 430px !important;
    min-height: 430px !important;
  }

  .carousel-detail .imgDetail {
    max-height: 410px !important;
    min-height: 410px !important;
  }

  #contenedorbotonescolorPC {
    display: none !important;
  }

  .btnColor {
    margin-top: 15% !important;
    padding: 10px !important;
  }

  #containerproductodetalle {
    margin-top: 0% !important;
    padding-top: 0% !important;
  }

}


@media(min-width: 576px) {
  #contenedorbotonescolorTEL {
    display: none !important;
  }



  #relleno {
    min-height: 230px !important;
  }

  .carousel-detail img {
    max-height: 550px !important;
    min-height: 550px !important;
  }

  .carousel-detail .imgDetail {
    max-height: 550px !important;
    min-height: 550px !important;
  }

  .borderSelected {
    box-shadow: rgba(5, 118, 247, 0.3) 0px 0px 0px 3px !important;
  }
}
</style>