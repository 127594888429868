<template>
  <div class="container padding">
    <div class="row">
      <div class="row mb-md-4 mb-lg-4 mb-0 mt-5 mt-md-5 mt-lg-5">
        <div class="row align-items-start text-left pb-1">
        <BackButton class="align-items-start" />
      </div>
        <div class="col text-center mt-5 mt-md-4 mt-lg-4">
          <h2 class="margin-title">Mi Carrito</h2>
        </div>
      </div>
    </div>
    <div class="row mt-1 px-md-5">
      <div class="col-12 col-sm-12 px-md-5">
        <div class="card text-center text-sm-start border-0">
          <ul class="list-group">
            <li class="list-group-item" v-for="product in products" :key="product.codigo">
              <div class="row margin-right">
                <div class="col-12 col-md-7 col-sm-12">
                  <div class="card border-0">
                    <div class="row">
                      <div class="col-md-4 col-sm-4 col-4">
                        <a href="javascript:void(0)" @click="viewDetail(product)">
                          <img :src="[
                            product.imagen.includes('no-img')
                              ? '/img/new-products/no-img.jpg'
                              : product.ruta + product.imagen,
                          ]" class="img-fluid rounded-start img-cart img-card" :alt="product.imagen" style="
                            object-fit: scale-down;
                            width: 100%;
                            height: 200px;
                          " />
                        </a>
                      </div>
                      <div class="col-md-8 col-sm-8 col-8 texto-izquierda">
                        <div class="card-body pt-0 pt-md-3">

                          <h5 class="card-title ch5">{{ product.nombreProducto }}</h5>
                          <h5 class="card-subtitle fw-bold ch5">
                            Precio: {{
                              formatMoney(issuserParam.monedalp)
                            }}{{ formatPrice(product.precioVenta, decimal) }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-5 mt-4 col-md-2 px-md-0 px-sm-0 px-0 col-sm-6 mt-md-3 botones">
                  <div class="row input-group px-2 px-sm-3 input-group-sm ml-5 input-group-xs w-md-60 justify-content-center justify-content-sm-start cantbuttons ig-size">
                    <button class="btn btn-outline-secondary col-3 minusbutton input-cant d-flex" type="button"
                      @click="restarCantidad(product)">
                      <fa icon="minus" />
                    </button>
                    <input class="input-cant
                                  form-control
                                  inputcount
                                  p-sm-0
                                  text-center
                                  border-0
                                " type="number" min="1" step="1"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" style="
                                  width: 25%;
                                  flex: none;
                                  margin: 1px;
                                  padding: inherit;
                                " v-model="product.cantidad" @update:modelValue="updateProduct(product)" />
                    <button class="btn btn-outline-secondary col-3 plusbutton input-cant d-flex" type="button"
                      @click="sumarCantidad(product)">
                      <fa icon="plus" />
                    </button>
                  </div>
                </div>

                <div class="
                  col-7 col-sm-6 col-md-3
                  d-flex
                  align-items-end
                  flex-column
                  bd-highlight
                  mt-md-3
                  mb-xs-5
                ">
                  <div class="row w-100 text-end justify-content-end pr-md-5">
                    <div class="bd-highlight col-8 col-md-12 precio">
                      <h6 class="fw-bold ch5">
                        {{
                          formatMoney(issuserParam.monedalp)
                        }}{{ formatPrice(product.subTotal, decimal) }}
                      </h6>
                    </div>
                    <div
                      class="mt-auto p-2 bd-highlight col-4 col-md-12 align-items-end align-content-end justify-content-end al-md-b">
                      <button class="btn btn-outline-danger mt-1  float-md-bottom bottom-0 margin-right px-md-2 px-lg-2 px-1 py-md-2 py-lg-2 py-1"
                        @click="deleteProduct(product.codigo)">
                        <fa icon="trash" />
                      </button>
                    </div>
                  </div>

                </div>
              </div>

            </li>
          </ul>
          <ul class="list-group border">
            <li class="list-group-item">
              <div class="row"> 
                <div class="text-end">
                  <h4 class="fw-bold">
                    Total: {{ formatMoney(issuserParam.monedalp) }}
                    {{ formatPrice(total, decimal) }}
                  </h4>
                </div>
              </div>
            </li>
          </ul>
          <div class="card-body pb-5">
            <div class="row pb-5">
              <div class="d-grid col-12 col-sm-6 text-center mt-1">
                <button class="btn btn-client-invertido btn-client-border" @click="router.push({ name: 'Home' })">
                  <fa icon="cart-arrow-down" /> Continuar Comprando
                </button>
              </div>
              <div class="d-grid col-12 col-sm-6 text-center mt-1">
                <button class="btn btn-client" @click="router.push({ name: 'Checkout' })">
                  <fa icon="shopping-cart" /> Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, watch, ref, onMounted } from "vue";
import {
  calcSalePrice,
  calcSubTotal,
  adjust10th,
  formatPrice
} from "@/composable/functions";
import router from "@/router";
import BackButton from "@/components/BackButton.vue";
export default {
  components: {
    BackButton
  },
  setup() {
    const decimal = inject("decimal");
    const cart = inject("cart");
    const getApiGeneric = inject("getApiGeneric");
    const saveProductoApp = inject("saveProducto");
    const issuserParam = inject("issuserParam");
    const styleClass = inject("styleClass");
    const products = ref([]);
    const total = ref(0);

    const instanceProducto = (product, index) => {

      let url = `productos/detalle`;
      let param = { producto: product.id, lp: issuserParam.value.idLP };

      //lo dejo por las dudas pero funciona llamando de aca nomas y con esto soluciono el problema que tenia con la llamada a productos/detalle que rompia el carrito
      //por el formato que vienen los datos
      // if (issuserParam.value.idDeposito > 0) {
      url = `productosStock/detalle`;
      param.deposito = issuserParam.value.idDeposito;
      // }
      console.log("params " + JSON.stringify(param))
      getApiGeneric(url, param, (prod) => {
        prod.retorno.index = index;
        prod.retorno.cantidad = product.cantidad;
        prod.retorno.nombreProducto = product.nombre
          ? product.nombre
          : prod.retorno.nombreProducto;
        prod.retorno.nombre = prod.retorno.nombreProducto;
        prod.retorno.precioVenta = (
          parseFloat(
            calcSalePrice(
              prod.retorno.precioUnitario,
              prod.retorno.pctDescuento,
              decimal
            )
          ) +
          adjust10th(
            issuserParam.value.configRedondeoDecimas,
            calcSalePrice(
              prod.retorno.precioUnitario,
              prod.retorno.pctDescuento,
              decimal
            ),
            decimal
          )
        ).toFixed(decimal);
        prod.retorno.subTotal = calcSubTotal(
          prod.retorno.precioVenta,
          prod.retorno.cantidad
        );
        total.value += parseFloat(prod.retorno.subTotal);
        products.value.push(prod.retorno);
        products.value.sort((a, b) => {
          return a.index < b.index;
        });
      });
    };

    if (issuserParam.value.idLP) {
      cart.value.forEach(instanceProducto);
    }

    watch(
      () => issuserParam.value,
      (newParams) => {
        if (!newParams) return;
        cart.value.forEach(instanceProducto);
      }
    );

    const updateProduct = (prod) => {
      if (!prod.cantidad) {
        return;
      }
      let cantidad = prod.cantidad;
      let cantidadResult = saveProducto(prod).cantidad;
      if (cantidadResult !== cantidad) {
        prod.cantidad = cantidadResult;
        updateProduct(prod);
      }
      prod.subTotal = calcSubTotal(prod.precioVenta, prod.cantidad, decimal);
      calcTotal();
    };

    const saveProducto = (prod) => {
      return saveProductoApp(prod, null, true);
    };

    const sumarCantidad = (product) => {
      product.cantidad = parseFloat(product.cantidad) + 1;
      updateProduct(product);
      return product;
    };
    const restarCantidad = (product) => {
      product.cantidad =
        product.cantidad - 1 ? parseFloat(product.cantidad) - 1 : 1;
      updateProduct(product);
      return product;
    };

    const deleteProduct = (codigo) => {
      let index = cart.value.findIndex((product) => product.id === codigo);
      cart.value.splice(index, 1);
      index = products.value.findIndex((product) => product.codigo === codigo);
      products.value.splice(index, 1);
      saveProducto();
      calcTotal();
    };

    const calcTotal = () => {
      total.value = products.value.reduce((acc, curr) => {
        return acc + parseFloat(curr.subTotal);
      }, 0);
      return total.value;
    };

    const viewDetail = (producto) => {
      router.push({ name: "DetailArticle", params: { id: producto.codigo } });
    };

    onMounted(() => {
      window.scrollTo(0, 0);
    });

    return {
      products,
      issuserParam,
      sumarCantidad,
      restarCantidad,
      updateProduct,
      total,
      deleteProduct,
      router,
      decimal,
      styleClass,
      viewDetail,
      formatPrice
    };
  },
};
</script>

<style>
@media(max-width: 576px) {
  .botones {
    
    padding-right: 0%!important;
  }
  .img-cart {
    height: auto !important;
  }
  .ig-size{
    padding-right: 0%!important;
  }
  .ch5 {
    font-size: small!important;
  }

  .texto-izquierda {
    margin-top: 0% !important;
    padding-top: 0% !important;
    text-align: left !important;
     height: 0px!important;
  }

  .precio {
    margin-top: 18% !important;
    margin-right: 0% !important;
    padding-top: 0% !important;
  }
}

@media(min-width: 576px){
    .al-md-b{
      align-items: bottom !important;
      bottom: 0%!important;
    }
    .margin-right{
      margin-right: 1%!important;
    }
}
</style>