<template>
  <div class="row border-top border-2 mt-3" v-if="!productos.colReturn || !productos.colReturn.length" id="articlesDisplay">
    <div class="col text-center">
      <h5>No hay Artículos</h5>
    </div>
  </div>
  <div class="row" v-else>
    <div class="col">
      <div class="row mb-5">
        <div
          class="px-0 px-sm-1 card-desk mt-3"
          :class="[
            getResponsiveCard(issuserParam.cantidadProductosMostrar)
              ? getResponsiveCard(issuserParam.cantidadProductosMostrar)
              : getResponsiveCard('1'),
          ]"
          v-for="producto in productos.colReturn"
          :key="producto.codigo"
        >
          <article-product class="margin-1" :product="producto" />
        </div>
      </div>
      <div class="row mt-2">
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center flex-wrap">
            <li class="page-item" :class="[pagActive > 1 ? '' : 'text-danger']">
              <a
                href="#articlesDisplay"
                class="page-link"
                aria-disabled="true"
                v-on:click="cambiarPagina(pagActive - 1)"
                ><fa icon="arrow-left"
              /></a>
            </li>
            <li
              class="page-item"
              v-for="index in nroPaginas"
              :key="index"
              :class="[pagActive === index ? 'active' : '']"
            >
              <a
                href="#articlesDisplay"
                class="page-link"
                v-on:click="cambiarPagina(index)"
                >{{ index }}</a
              >
            </li>
            <li
              class="page-item"
              :class="[pagActive < nroPaginas ? '' : 'disabled']"
            >
              <a href="#articlesDisplay" class="page-link"
                ><fa icon="arrow-right" v-on:click="cambiarPagina(pagActive + 1)"
              /></a>
            </li>
          </ul>
        </nav>
      </div>

    </div>
  </div>
</template>

<script>
import { inject, ref, watchEffect } from "vue";
import { getResponsiveCard } from "@/composable/functions";

import ArticleProduct from "@/components/ArticleProduct.vue";
export default {
  components: {
    ArticleProduct,
  },
  setup() {
    const decimal = inject("decimal");
    const productos = inject("productos");
    const cargarProductos = inject("cargarProductos");
    const count = ref(24);
    const pagActive = inject("pagActive");
    const paramsProductos = inject("paramsProductos");
    const issuserParam = inject("issuserParam");
    const nroPaginas = ref(1);
    const favList = ref([]);

    favList.value = localStorage.getItem("fav")
      ? JSON.parse(localStorage.getItem("fav"))
      : [];

    const cambiarPagina = (index) => {
      pagActive.value = index;
      let start =
        index === 1 ? index : (index - 1) * paramsProductos.value.count;
      paramsProductos.value.start = start;
      cargarProductos(paramsProductos.value);
    };

    watchEffect(() => {
      nroPaginas.value = productos.value.cantTotResult
        ? Math.ceil(productos.value.cantTotResult / paramsProductos.value.count)
        : 1;
    });

    return {
      productos,
      pagActive,
      count,
      nroPaginas,
      cambiarPagina,
      issuserParam,
      decimal,
      getResponsiveCard,
    };
  },
};

</script>

<style>
.pagination > li > a {
  background-color: white;
  color: #6c757d;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #5a5a5a;
  background-color: #eee;
  border-color: #ddd;
}

.pagination > .active > a {
  color: white;
  background-color: #6c757d !important;
  border: solid 1px #6c757d !important;
}

.pagination > .active > a:hover {
  background-color: #6c757d !important;
  border: solid 1px #6c757d;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


@media(max-width: 576px){
   .margin-1{
    margin: 2%;
   }
}
</style>