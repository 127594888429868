<template>
  <div
    class="modal fade"
    id="pagowebModal"
    style=""
    tabindex="-1"
    role="dialog"
    aria-labelledby="tituloPagoWeb"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="tituloPagoWeb">Pago Web</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            id="iframeDiv"
            style="height: 410px; width: 300px; margin: auto"
          ></div>
          <div
            id="custom_iframe"
            style="height: auto; width: 20px; margin: auto"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, watch } from "vue";
import { Modal } from "bootstrap";
import router from "@/router";
export default {
  setup(props, { expose }) {
    let modal;
    const postApiGeneric = inject("postApiGeneric");
    const issuserParam = inject("issuserParam");
    const totalCheckout = inject("totalCheckout");
    const computedTotalCheckout = inject("computedTotalCheckout");
    const products = inject("productsCheckout");
    const precioEnvio = inject("precioEnvioCheckout");
    const cliente = inject("cliente");
    const pedidoFE = inject("pedidoFE");
    const siemprePagoSelected = inject("siemprePagoSelected");
    const instanceSiemprePago = () => {
      const scripts = [
        `${issuserParam.value.url_PagoWeb}/Scripts/PWCheckoutNoModal.js?key=${
          issuserParam.value.keyBamboo
            ? issuserParam.value.keyBamboo
            : "xNrCXLxxTvq_O_bxMzSP3m_I56ONV7HQ"
        }`,
      ];
      scripts.forEach((script) => {
        let tag = document.createElement("script");
        tag.setAttribute("src", script);
        document.head.appendChild(tag);
      });
    };

    if (issuserParam.value.url_PagoWeb) {
      instanceSiemprePago();
    }

    watch(
      () => issuserParam.value,
      (newParams) => {
        if (!newParams) return;
        instanceSiemprePago();
      }
    );

    const cargarModal = (fn) => {
      modal = new Modal(document.getElementById("pagowebModal"));
      OpenIframe(siemprePagoSelected.value.idSello);
      if (fn) {
        fn(modal);
      }
    };

    const onTokenCreated = (token) =>{
        token = JSON.parse(JSON.stringify(token));
        generaPedidoPagoWeb(token.TokenId);
    }

    const NotificationHandler = (notification)=> {
        alert("notificacion!!!!");
        if (notification.ProcessStatus == 1)
        {
            alert("ANDUVO AL PELO");
        } else {
            alert("SE ROMPIO");
        }
    }

    const generaPedidoPagoWeb = (token) =>{

        let idPrecioEnvio = precioEnvio.value.idPrecio;
        let carritoDD = [
            {"variable": "moneda", "variableValor": issuserParam.value.monedalp}
        ];

        var objEnvioPedido = {};
        objEnvioPedido.nombrePedido = cliente.nombre;
        objEnvioPedido.direccionPedido = cliente.direccion;
        objEnvioPedido.ciudadPedido = cliente.ciudad;
        objEnvioPedido.emailPedido = cliente.email;
        objEnvioPedido.telefonoPedido = cliente.telefono;
        objEnvioPedido.comentarioPedido = cliente.comentario;
        objEnvioPedido.documentoReceptor = cliente.documentoReceptor;
        objEnvioPedido.departamentoPedido = cliente.departamento;
        objEnvioPedido.tipoDocumento = cliente.tipoDocumentoReceptor;
        objEnvioPedido.idPrecioEnvio= idPrecioEnvio;
        objEnvioPedido.carritoDD = carritoDD;
        objEnvioPedido.total= computedTotalCheckout.value;
        objEnvioPedido.totalSinCambios= totalCheckout.value;
        objEnvioPedido.TrxToken= token;

        postApiGeneric("pasarelas/siemprePago",
        {
            objPedido: objEnvioPedido,
            listaCarritoCompras: products.value
        },
        (data)=>{   
            pedidoFE.value = data;
            router.push({name:'pagoWebAprobado'});
        });
        // facturarPagoWeb(objEnvioPedido);
            
    }

    const OpenIframe = (xIdTarjeta) =>{      
            let main_iframe = document.createElement("iframe");
            let iframeDiv = document.getElementById("iframeDiv");
            iframeDiv.innerHTML = '';
            main_iframe.id = "custom_iframe";
            // Append iframe to div
            iframeDiv.appendChild(main_iframe);
            // iframe styles
            main_iframe.setAttribute("frameborder", "0");
            main_iframe.style.borderRadius = "10px";
            main_iframe.style.height = "100%";
            main_iframe.style.width = "100%";
            main_iframe.style.zIndex = "9999";
            main_iframe.style.display = "block";
            main_iframe.scrolling = "no";

            window.PWCheckout.SetProperties(
                {
                    "name": issuserParam.value.appName,
                    "button_label": "Pagar " + issuserParam.value.monedalp +" "+ computedTotalCheckout.value,
                    "description": "Compra Market Express",
                    "currency":issuserParam.value.monedalp,
                    "amount": "1",
                    "lang": "ESP",
                    "form_id": "commerce_form",
                    "checkout_card": "0",
                    "autoSubmit": "false",
                    "iframeId": "custom_iframe",
                    "email_edit": "false",
                    "close_onclick":true
                }
        );
                //Openl Iframe for Visa
            window.PWCheckout.LoadIframe(xIdTarjeta);
            window.PWCheckout.Bind("tokenCreated", onTokenCreated);
            window.PWCheckout.Bind("notificationReceived", NotificationHandler);
        }    

    expose({cargarModal});

    
  },
};
</script>

<style>
</style>